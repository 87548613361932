import React, { useEffect, useState } from "react";
import {
  airtel,
  bsnl,
  serviceCircle,
  jio,
  logo,
  mtnl,
  vi,
} from "../../../images";
import {
  checkRechgType,
  getMobOperator,
  browseMobPlans,
  mobRecharge,
} from "../../../urls";
import API from "../../../components/api2";
import Select, { components } from "react-select";
import loaderGif from "../../../gif/buffer.gif";
import Swal from "sweetalert2";
import CustomerList from "./CustomerList";

const { Option } = components;

const MobileRecharge = ({ serviceName }) => {
  const [formData, setFormData] = useState({
    mobile_number: "",
    operator_id: "",
    operator_name: "",
    circle: "",
    transaction_amount: "",
    upin: "",
  });

  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredPlans, setFilteredPlans] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [operators, setOperators] = useState([]);
  const [plans, setPlans] = useState([]);
  const [headings, setHeadings] = useState([]);
  const [loader, setLoader] = useState(false);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [showCustomerList, setShowCustomerList] = useState(true);

  const handlePriceClick = (price) => {
    setFormData((prevState) => ({
      ...prevState,
      transaction_amount: price,
    }));
    setSelectedPrice(price);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile_number") {
      const formattedValue = value.replace(/\D/g, "");
      if (formattedValue.length <= 10) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      operator_id: selectedOption.value,
      operator_name: selectedOption.label,
    }));
  };

  // apis

  // get operator
  useEffect(() => {
    const getOperators = () => {
      API({
        ...getMobOperator,
        onSuccess: (res) => {
          const prepaidOperators = res.data.data
            .filter((operator) => operator.category === "Prepaid")
            .map((operator) => ({
              value: operator.id,
              label: operator.name,
              imageUrl: getImageUrl(operator.name),
            }));
          setOperators(prepaidOperators);
        },
        onError: (error) => {
          console.log("error fetching operators", error);
        },
      });
    };
    getOperators();
  }, []);

  // check recharge type
  useEffect(() => {
    const checkType = () => {
      if (formData.mobile_number && formData.mobile_number.length === 10) {
        setLoader(true);
        API({
          ...checkRechgType,
          body: {
            number: parseInt(formData.mobile_number, 10),
            type: "mobile",
          },
          onSuccess: (res) => {
            const operatorId = res.data.data.operator;
            const operator = operators.find(
              (op) => op.label === operatorId.toString()
            );
            if (operator) {
              setFormData((prevState) => ({
                ...prevState,
                operator_id: operator.value,
                operator_name: operator.label,
                circle: res.data.data.circle,
              }));
            }
            setLoader(false);
          },
          onError: (error) => {
            console.log("error getting recharge type", error);
            setLoader(true);
          },
        });
      }
    };
    checkType();
  }, [formData.mobile_number, checkRechgType, operators]);

  const getImageUrl = (name) => {
    const imageUrls = {
      Airtel: airtel,
      BSNL: bsnl,
      Idea: vi,
      Vodafone: vi,
      Jio: jio,
      MTNL: mtnl,
      "MTNL Delhi": mtnl,
      "MTNL Mumbai": mtnl,
    };
    return imageUrls[name] || logo;
  };

  const CustomOption = (props) => (
    <Option {...props}>
      <img
        src={props.data.imageUrl}
        alt={props.data.label}
        style={{ width: "24px", marginRight: "8px" }}
      />
      {props.data.label}
    </Option>
  );

  // browse plans
  useEffect(() => {
    const getPlans = () => {
      if (formData.circle && formData.operator_id) {
        setLoader(true);
        API({
          ...browseMobPlans,
          body: { circle: formData.circle, operator: formData.operator_id },
          onSuccess: (res) => {
            if (typeof res.data.data === "object" && res.data.data !== null) {
              const extractedHeadings = Object.keys(res.data.data);

              setHeadings(extractedHeadings);
              setPlans(res.data.data);
            }
            setLoader(false);
          },
          onError: (error) => {
            console.log("error fetching plans", error);
            setLoader(false);
          },
        });
      }
    };
    getPlans();
  }, [formData.circle, formData.operator_id]);

  const handleTabClick = (category) => {
    setSelectedCategory(category);
    setFilteredPlans(plans[category]);
  };

  const handleOperatorClick = (operatorName) => {
    const operator = operators.find((op) => op.label === operatorName);
    setFormData((prevState) => ({
      ...prevState,
      operator_id: operator ? operator.value : "",
      operator_name: operatorName,
    }));
  };

  // validation
  const checkAllFieldsFilled = () => {
    const { mobile_number, operator_id, circle, transaction_amount } = formData;
    if (mobile_number && operator_id && circle && transaction_amount) {
      setAllFieldsFilled(true);
    } else {
      setAllFieldsFilled(false);
    }
  };

  useEffect(() => {
    checkAllFieldsFilled();
  }, [formData]);

  // handle recharge
  const handleRecharge = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Confirm Recharge",
      text: "Are you sure you want to proceed with the recharge?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((confirmation) => {
      if (confirmation.isConfirmed) {
        Swal.fire({
          title: "Enter UPIN",
          input: "password",
          inputAttributes: {
            maxlength: "6",
            autocapitalize: "off",
            autocorrect: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          inputValidator: (value) => {
            if (!value || value.length !== 6) {
              return "UPIN must be a 6-digit number";
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setLoader(true);
            const payload = {
              ...formData,
              operator_id: parseInt(formData.operator_id, 10),
              mobile_number: parseInt(formData.mobile_number, 10),
              upin: parseInt(result.value),
            };
            API({
              ...mobRecharge,
              body: payload,
              onSuccess: (res) => {
                setLoader(false);
                Swal.fire({
                  title: `${res.data.message}`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
              },
              onError: (error) => {
                setLoader(false);
                Swal.fire({
                  icon: "error",
                  title: `${error.response.data.message}`,
                });
              },
            });
          }
        });
      }
    });
  };

  const handleCustomerClick = (mobileNumber) => {
    setFormData((prevState) => ({
      ...prevState,
      mobile_number: mobileNumber,
    }));
    setShowCustomerList(false);
  };
  return (
    <>
      {showCustomerList ? (
        <CustomerList handleCustomerClick={handleCustomerClick} />
      ) : (
        <>
          {/* recharge box  */}
          <div
            className="position-relative mx-5 mb-3 px-2"
            style={{
              border: "1px solid rgba(217, 217, 217, 1)",
              boxShadow: "0px 4px 40px 10px rgba(0, 0, 0, 0.15)",
              borderRadius: "20px",
            }}
          >
            <img
              style={{
                position: "absolute",
                right: "0",
                top: "0",
                zIndex: "-1",
              }}
              src={serviceCircle}
              alt="circle"
            />
            <div className="row">
              {/* recharge form  */}
              <div
                style={{ borderRight: "1px solid #D9D9D9" }}
                className="col-3 py-3"
              >
                <div
                  className="py-3 px-4"
                  style={{
                    backgroundColor: "rgba(25, 66, 128, 1)",
                    borderRadius: "20px",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "500",
                      fontSize: "20px",
                      color: "white",
                    }}
                    className="mb-1"
                  >
                    {serviceName}
                  </p>
                  <span
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "rgba(32, 49, 79, 1)",
                    }}
                    className="mb-0 text-white px-3 py-1 trans_text"
                  >
                    Prepaid
                  </span>
                  <form className="mx-2" onSubmit={handleRecharge}>
                    <label
                      htmlFor="mobile_number"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        fontSize: "14px",
                        opacity: formData.mobile_number ? 1 : 0,
                        marginTop: "8px",
                      }}
                      className="ms-2 trans_text"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="number"
                      id="mobile_number"
                      name="mobile_number"
                      value={formData.mobile_number}
                      onChange={handleInputChange}
                      placeholder="Mobile Number"
                      style={{
                        backgroundColor: "transparent",
                        borderBottom: formData.mobile_number
                          ? "1.5px solid white"
                          : "1.5px solid #ADACAC",
                        width: "100%",
                        color: "white",
                        borderRadius: "0px",
                        padding: "0px 8px",
                      }}
                      autoComplete="off"
                      className="trans_text"
                      readOnly
                    />
                    <label
                      htmlFor="operator_id"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        fontSize: "14px",
                        opacity: formData.operator_id ? 1 : 0,
                        paddingTop: "10px",
                      }}
                      className="ms-2 trans_text"
                    >
                      Select Operator
                    </label>
                    <Select
                      id="operator_id"
                      name="operator_id"
                      className="recharge_select trans_text"
                      placeholder="Select Operator"
                      value={operators.find(
                        (option) => option.value === formData.operator_id
                      )}
                      options={operators}
                      components={{
                        Option: CustomOption,
                      }}
                      onChange={handleSelectChange}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "transparent",
                          borderWidth: "0 0 1.5px 0",
                          borderBottom: formData.operator_id
                            ? "1.5px solid white"
                            : "1.5px solid #ADACAC",
                          width: "100%",
                          color: formData.operator_id ? "white" : "#ADACAC",
                          borderRadius: "0px",
                          boxShadow: "none",
                          "&:focus": {
                            outline: "none",
                            boxShadow: "none",
                          },
                          marginTop: "-8px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "white",
                        }),
                      }}
                    />

                    <label
                      htmlFor="circle"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        fontSize: "14px",
                        opacity: formData.circle ? 1 : 0,
                        marginTop: "10px",
                      }}
                      className="ms-2 trans_text"
                    >
                      Circle
                    </label>
                    <input
                      type="text"
                      id="circle"
                      name="circle"
                      value={formData.circle}
                      onChange={handleInputChange}
                      placeholder="Circle"
                      readOnly={!!formData.circle}
                      style={{
                        backgroundColor: "transparent",
                        borderBottom: formData.circle
                          ? "1.5px solid white"
                          : "1.5px solid #ADACAC",
                        width: "100%",
                        color: "white",
                        borderRadius: "0px",
                        padding: "0px 8px",
                      }}
                      autoComplete="off"
                      className="trans_text"
                    />

                    <label
                      htmlFor="transaction_amount"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        fontSize: "14px",
                        opacity: formData.transaction_amount ? 1 : 0,
                        marginTop: "10px",
                      }}
                      className="ms-2 trans_text"
                    >
                      Amount
                    </label>
                    <input
                      type="number"
                      id="transaction_amount"
                      name="transaction_amount"
                      value={formData.transaction_amount}
                      onChange={handleInputChange}
                      placeholder="Amount"
                      style={{
                        backgroundColor: "transparent",
                        borderBottom: formData.transaction_amount
                          ? "1.5px solid white"
                          : "1.5px solid #ADACAC",
                        width: "100%",
                        color: "white",
                        borderRadius: "0px",
                        padding: "0px 8px",
                      }}
                      autoComplete="off"
                      className="trans_text"
                    />
                    <div className="text-center mt-4">
                      <button
                        className="px-4 py-2 trans_text"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "10px",
                          border: "none",
                          fontSize: "15px",
                          fontWeight: "500",
                          cursor: allFieldsFilled ? "pointer" : "not-allowed",
                          opacity: allFieldsFilled ? 1 : 0.5,
                        }}
                        type="submit"
                        disabled={!allFieldsFilled}
                      >
                        Proceed to recharge
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              {/* plans  */}
              <div className="col-9 py-4 position-relative">
                {formData.mobile_number &&
                  formData.mobile_number.length === 10 &&
                  (loader ? (
                    <img
                      className="position-absolute"
                      style={{
                        height: "70px",
                        width: "70px",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      src={loaderGif}
                      alt="Loading..."
                    />
                  ) : (
                    <>
                      <div
                        className="ms-4"
                        style={{
                          borderBottom: "1.5px solid #D9D9D9",
                          marginRight: "55px",
                        }}
                      >
                        <h2
                          className="d-inline-block trans_text"
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            borderBottom: "2px solid #194280",
                          }}
                        >
                          Browse Plan
                        </h2>
                      </div>

                      <h2
                        style={{ fontWeight: "500", fontSize: "18px" }}
                        className="mt-2 ms-4"
                      >
                        {`Browse Plan of ${formData.operator_name} - ${formData.circle}`}
                      </h2>

                      <div
                        style={{
                          backgroundColor: "#E6E6E6",
                          borderRadius: "10px",
                        }}
                        className="mt-3 trans_text"
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          {headings.map((h, index) => (
                            <p
                              key={index}
                              onClick={() => handleTabClick(h)}
                              style={{
                                fontWeight: "500",
                                fontSize: "15px",
                                color:
                                  selectedCategory === h
                                    ? "black"
                                    : "rgba(105, 102, 102, 1)",
                                cursor: "pointer",
                                backgroundColor:
                                  selectedCategory === h
                                    ? "#D9D9D9"
                                    : "transparent",
                                borderBottom:
                                  selectedCategory === h
                                    ? "2px solid #194280"
                                    : "none",
                              }}
                              className="mb-0 py-1 px-3 trans_text"
                            >
                              {h}
                            </p>
                          ))}
                        </div>
                      </div>

                      <div
                        className="row ms-1 mt-3 trans_text"
                        style={{
                          color: "rgba(105, 102, 102, 1)",
                          fontSize: "15px",
                        }}
                      >
                        <div className="col-2">Circle</div>
                        <div className="col-2">Plan Type</div>
                        <div className="col-1">Data</div>
                        <div className="col-2">Validity</div>
                        <div className="col-3">Description</div>
                        <div className="col-2 ps-5">Price</div>
                      </div>

                      <div
                        style={{
                          overflowY: "scroll",
                          overflowX: "hidden",
                          height: "190px",
                        }}
                      >
                        {selectedCategory ? (
                          filteredPlans && filteredPlans.length > 0 ? (
                            <div className="row ms-2 mt-3">
                              {filteredPlans.map((plan, index) => (
                                <div
                                  key={index}
                                  className="d-flex mt-3 trans_text"
                                >
                                  <div
                                    className="col-2 text-black"
                                    style={{ fontWeight: "500" }}
                                  >
                                    {formData.circle}
                                  </div>
                                  <div
                                    className="col-2 text-black"
                                    style={{ fontWeight: "500" }}
                                  >
                                    Recharge
                                  </div>
                                  <div
                                    className="col-1 text-black"
                                    style={{ fontWeight: "500" }}
                                  >
                                    {"N/A"}
                                  </div>
                                  <div
                                    className="col-2 text-black"
                                    style={{ fontWeight: "500" }}
                                  >
                                    {plan.validity}
                                  </div>
                                  <div
                                    className="col-3 text-black"
                                    style={{ fontWeight: "500" }}
                                  >
                                    {plan.desc}
                                  </div>
                                  <div className="col-2 ps-5">
                                    <p
                                      className="px-2 py-1 d-inline"
                                      style={{
                                        fontWeight: "500",
                                        color:
                                          selectedPrice === plan.rs
                                            ? "white"
                                            : "#194280",
                                        border: "1.5px solid #194280",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                        backgroundColor:
                                          selectedPrice === plan.rs
                                            ? "#194280"
                                            : "transparent",
                                      }}
                                      onClick={() => handlePriceClick(plan.rs)}
                                    >
                                      Rs. {plan.rs}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div
                              style={{
                                color: "black",
                                fontWeight: "500",
                                fontSize: "18px",
                              }}
                              className="text-center mt-5"
                            >
                              No plans available
                            </div>
                          )
                        ) : null}
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
          {/* choose operator  */}
          <div
            style={{
              border: "1px solid rgba(217, 217, 217, 1)",
              boxShadow: "0px 4px 40px 10px rgba(0, 0, 0, 0.15)",
              borderRadius: "20px",
            }}
            className="mb-3 mx-5 p-4"
          >
            <h2 style={{ fontWeight: "500", fontSize: "20px" }}>
              Choose an Operator
            </h2>
            <div className="d-flex align-items-center mt-4">
              <div
                className="text-center"
                onClick={() => handleOperatorClick("Jio")}
              >
                <img
                  style={{ cursor: "pointer", pointerEvents: "all" }}
                  src={jio}
                  alt="jio"
                />
                <p
                  style={{ fontSize: "15px", color: "rgba(105, 102, 102, 1)" }}
                  className="mb-0 mt-2 trans_text"
                >
                  JIO Recharge
                </p>
              </div>
              <div
                className="ms-5 text-center"
                onClick={() => handleOperatorClick("BSNL")}
              >
                <img
                  style={{ cursor: "pointer", pointerEvents: "all" }}
                  src={bsnl}
                  alt="bsnl"
                />
                <p
                  style={{ fontSize: "15px", color: "rgba(105, 102, 102, 1)" }}
                  className="mb-0 mt-2 trans_text"
                >
                  BSNL Recharge
                </p>
              </div>
              <div
                className="ms-5 text-center"
                onClick={() => handleOperatorClick("Airtel")}
              >
                <img
                  style={{ cursor: "pointer", pointerEvents: "all" }}
                  src={airtel}
                  alt="airtel"
                />
                <p
                  style={{ fontSize: "15px", color: "rgba(105, 102, 102, 1)" }}
                  className="mb-0 mt-2 trans_text"
                >
                  Airtel Recharge
                </p>
              </div>
              <div
                className="ms-5 text-center"
                onClick={() => handleOperatorClick("MTNL")}
              >
                <img
                  style={{ cursor: "pointer", pointerEvents: "all" }}
                  src={mtnl}
                  alt="mtnl"
                />
                <p
                  style={{ fontSize: "15px", color: "rgba(105, 102, 102, 1)" }}
                  className="mb-0 mt-2 trans_text"
                >
                  MTNL Recharge
                </p>
              </div>
              <div
                className="ms-5 text-center"
                onClick={() => handleOperatorClick("Vodafone")}
              >
                <img
                  style={{ cursor: "pointer", pointerEvents: "all" }}
                  src={vi}
                  alt="vi"
                />
                <p
                  style={{ fontSize: "15px", color: "rgba(105, 102, 102, 1)" }}
                  className="mb-0 mt-2 trans_text"
                >
                  VI Recharge
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MobileRecharge;
