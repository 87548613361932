import React, { useEffect, useState } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import API from "../components/api2";
import { getDUID, login, logout } from "../urls";
import { dots, loginBg, newLogo } from "../images/index";
import { changePage } from "../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { setDUID, onLogin, onLogout } from "../redux/slice/userSlice";
import { connect, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import ValidateUser from "./ValidateUser";
import QRPage from "./QRPage";
import axios from "axios";

const LoginPage = ({ duid, t }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validVU, setValidVU] = useState(false);
  const [loggingInWithQR, setLoggingInWithQR] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [ip, setIp] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(onLogout());
  }, [dispatch]);

  useEffect(() => {
    localStorage.clear();
    if (!duid) {
      API({
        ...getDUID,
        onSuccess: (res) => {
          dispatch(
            setDUID({
              duid: res.data.data.duid,
            })
          );
        },
        onError: (error) => {
          alert(error.response.data.message);
        },
      });
    }
  }, [duid, t]);

  // get ip
  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((res) => {
        setIp(res.data.ip);
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  }, []);

  // get coordinates
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting location", error);
          setErrorMessage("Unable to retrieve your location.");
        }
      );
    } else {
      setErrorMessage("Geolocation is not supported by this browser.");
    }
  }, []);

  // on submit
  function onSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    setErrorMessage("");

    if (!username || !password) {
      setErrorMessage("Username or password cannot be empty");
      return;
    }

    let submitFormData = new FormData();
    submitFormData.append("password", password);
    submitFormData.append("username", username);
    submitFormData.append("duid", duid);
    submitFormData.append("source", "WEB");
    submitFormData.append("ip_address", ip);

    if (latitude && longitude) {
      submitFormData.append("latitude", latitude);
      submitFormData.append("longitude", longitude);
    }

    API({
      ...login,
      body: submitFormData,
      isFormData: true,
      source: "WEB",
      retry: true,
      onSuccess: (res) => {
        let data = {};
        res.data.data = res.data.data.map((item) => {
          data[item["key"]] = item["value"];
          return item;
        });
        res.data.data = data;

        const reqId = res.data.data.request_id;
        const password_reset_required = res.data.data.change_password_required;
        const firstLogin = res.data.data.first_login;
        const otp_sent = res.data.data.otp_sent;
        const user_pin_required = res.data.data.user_pin_required;
        const secret_question_required = res.data.data.secret_question_required;
        const email_verification_required =
          res.data.data.email_verification_required;

        changePageFunc("firstLogin", reqId);

        if (res.data.data.t) {
          if (firstLogin) {
            changePageFunc("firstLogin");
          } else if (password_reset_required) {
            setErrorMessage("Please change your password");
            changePageFunc("changePassword");
          } else if (email_verification_required) {
            changePageFunc("verifyMail");
          } else if (user_pin_required) {
            changePageFunc("setUpin");
          } else if (secret_question_required) {
            changePageFunc("getQuestions");
          } else if (otp_sent) {
            changePageFunc("verifyOTP", reqId);
          }
          dispatch(
            onLogin({
              username: username,
              name: res.data.data.name,
              registered_no: res.data.data.registered_no,
              registered_id: res.data.data.registered_id,
              t: res.data.data.t,
              rt: res.data.data.rt,
            })
          );
        }
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
        setErrorMessage(error.response.data.message);
      },
    });
  }
  // handle forgot pass
  const handleForgotPassword = () => {
    setValidVU(true);
  };

  // qr login
  const handleQRLogin = () => {
    setLoggingInWithQR(!loggingInWithQR);
  };

  // change page
  const changePageFunc = (targetPage, reqId) => {
    dispatch(
      changePage({
        page: targetPage,
        reqId: reqId,
      })
    );
    navigate("/", {
      state: {
        username,
        password,
        reqId,
      },
    });
  };

  return (
    <>
      {validVU ? (
        <>
          <ValidateUser latitude={latitude} longitude={longitude} />
        </>
      ) : (
        <section className="bg-white">
          <div className="container-fluid d-flex min-vh-100 ">
            <div className="row align-items-center flex-grow-1 ">
              <div className="col-6 ps-0">
                <img
                  style={{ width: "95%", height: "100vh" }}
                  src={loginBg}
                  alt="login-bg"
                />
              </div>
              <div className="col-5 ms-4 text-center position-relative">
                {loggingInWithQR ? (
                  <>
                    <QRPage />
                    <p
                      className="mb-1 text-end ms-2"
                      style={{ color: "#194280", cursor: "pointer" }}
                      onClick={handleQRLogin}
                    >
                      Switch to Standard Login
                    </p>
                  </>
                ) : (
                  <>
                    <img
                      style={{
                        bottom: "-10%",
                        right: "-60px",
                        width: "110px",
                      }}
                      className="position-absolute login_dots"
                      src={dots}
                      alt="dots"
                    />
                    <img
                      style={{ maxWidth: "220px" }}
                      src={newLogo}
                      alt="logo"
                    />
                    <div className="position-relative mt-3">
                      <p
                        style={{
                          fontSize: "24px",
                          color: "rgba(25, 66, 128, 1)",
                        }}
                        className="mb-0 underline login_text"
                      >
                        Sign in
                      </p>
                    </div>

                    <div
                      className="mt-3 login_text"
                      style={{
                        fontSize: "18px",
                        color: "rgba(25, 66, 128, 1)",
                      }}
                    >
                      to continue to PrayasPe Portal.
                    </div>
                    <form onSubmit={onSubmit} className="pt-3">
                      <div className="d-flex flex-column">
                        <div className="position-relative">
                          <Input
                            type={"text"}
                            id={"username"}
                            name="username"
                            placeholder={"Enter your Username"}
                            showLabel={false}
                            value={username}
                            onChange={(e) => {
                              setUsername(e.target.value);
                              setErrorMessage("");
                            }}
                            showIcon={true}
                            isEmpty={!username && submitted}
                          />
                          {errorMessage && (
                            <p
                              style={{
                                position: "absolute",
                                color: "red",
                                right: "0",
                              }}
                            >
                              {errorMessage.includes("username") &&
                                errorMessage}
                            </p>
                          )}
                        </div>
                        <div className="position-relative">
                          <Input
                            type={"password"}
                            showLabel={false}
                            id={"password"}
                            name="password"
                            placeholder={"Enter your Password"}
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setErrorMessage("");
                            }}
                            showPassIcon={true}
                            isEmpty={!password && submitted}
                          />
                          {errorMessage && (
                            <p
                              style={{
                                position: "absolute",
                                color: "red",
                                right: "0",
                              }}
                            >
                              {errorMessage.includes("password") &&
                                errorMessage}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="my-4">
                        <div className="d-flex justify-content-end">
                          <p
                            className="mb-1 text-end login_text"
                            style={{ color: "#194280", cursor: "pointer" }}
                            onClick={handleForgotPassword}
                          >
                            Forgot Password?
                          </p>
                          <p
                            className="mb-1 text-end ms-2 login_text"
                            style={{ color: "#194280", cursor: "pointer" }}
                            onClick={handleQRLogin}
                          >
                            Log In with QR
                          </p>
                        </div>
                        <Button
                          type={"submit"}
                          style={{ marginLeft: "10px", width: "100%" }}
                          children={"Sign In"}
                        />
                        <p
                          className="mb-0 py-3 login_text"
                          style={{ color: "rgba(180, 178, 178, 1)" }}
                        >
                          2024 PrayasPe. All Rights Reserved
                        </p>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  duid: state.user.duid,
  t: state.user.t,
});

export default connect(mapStateToProps)(LoginPage);
