import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { useTranslation } from "react-i18next";
import { getMenuShortcut, transGraph } from "../urls";
import API from "./api2";
import { FaRegChartBar } from "react-icons/fa";
import { FaTableList } from "react-icons/fa6";
import Select from "react-select";

const MyChartComponent = () => {
  const { t } = useTranslation();

  const barChartRef = useRef(null);
  const pieChartRef = useRef(null);
  const [data, setData] = useState({
    daily: [],
    weekly: [],
    monthly: [],
    yearly: [],
    alternateView: [],
  });
  const [activeTab, setActiveTab] = useState("yearly");
  const [showTableView, setShowTableView] = useState(false);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const barChartInstanceRef = useRef(null);
  const pieChartInstanceRef = useRef(null);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // logged in services
  useEffect(() => {
    API({
      ...getMenuShortcut,
      onSuccess: (res) => {
        const formattedOptions = res.data.data.map((service) => ({
          value: service.service_id,
          label: service.utility_service_name,
        }));
        setServiceOptions(formattedOptions);
      },
      onError: (error) => {
        console.log("Error fetching services", error);
      },
    });
  }, []);

  const getTransGraph = () => {
    API({
      ...transGraph,
      params: { services_type: selectedOption?.label },
      onSuccess: (res) => {
        setData({
          ...data,
          daily: res.data.data.daily_transactions,
          weekly: res.data.data.weekly_transactions,
          monthly: res.data.data.monthly_transactions,
          yearly: res.data.data.yearly_transactions,
          alternateView: [],
        });
      },
      onError: (error) => {
        console.log("Error fetching data:", error);
      },
    });
  };

  useEffect(() => {
    getTransGraph();
  }, [selectedOption?.label]);

  useEffect(() => {
    const renderBarChart = () => {
      if (!barChartRef.current || !data[activeTab] || !data[activeTab].length) {
        console.log("Skipping rendering bar chart");
        return;
      }

      if (barChartInstanceRef.current) {
        barChartInstanceRef.current.destroy();
      }

      const selectedData = data[activeTab];
      let labels;
      if (activeTab === "daily") {
        labels = selectedData.map((entry, index) => `Day ${index + 1}`);
      } else if (activeTab === "weekly") {
        labels = selectedData.map((entry, index) => `Week ${index + 1}`);
      } else if (activeTab === "monthly") {
        labels = selectedData.map((entry) => entry.month_year);
      } else if (activeTab === "yearly") {
        labels = selectedData.map((entry) => entry.year);
      } else if (activeTab === "alternateView") {
        labels = selectedData.map((entry) => entry.label);
      }

      const config = {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Debit",
              data: selectedData.map((entry) => parseFloat(entry.debit)),
              backgroundColor: "#F97C4B",
              borderColor: "#F97C4B",
              borderWidth: 1,
              borderRadius: { topRight: 10, topLeft: 10 },
              barPercentage: 0.5,
            },
            {
              label: "Credit",
              data: selectedData.map((entry) => parseFloat(entry.credit)),
              backgroundColor: "#3D9189",
              borderColor: "#3D9189",
              borderWidth: 1,
              borderRadius: { topRight: 10, topLeft: 10 },
              barPercentage: 0.5,
            },
          ],
        },
        options: {
          scales: { x: { type: "category" }, y: { beginAtZero: true } },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              labels: {
                usePointStyle: true,
              },
              position: "top",
            },
          },
        },
      };

      if (activeTab === "weekly") {
        config.options.plugins.tooltip = {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              if (context.parsed.y !== null) {
                label += context.parsed.y.toFixed(2);
              }
              return label;
            },
            title: function (tooltipItem, data) {
              const index = tooltipItem[0].dataIndex;
              const weekData = selectedData[index].week;
              return `${weekData}`;
            },
          },
        };
      }
      if (activeTab === "daily") {
        config.options.plugins.tooltip = {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              if (context.parsed.y !== null) {
                label += context.parsed.y.toFixed(2);
              }
              return label;
            },
            title: function (tooltipItem, data) {
              const index = tooltipItem[0].dataIndex;
              const dailyData = selectedData[index].date;
              return `${dailyData}`;
            },
          },
        };
      }

      barChartInstanceRef.current = new Chart(barChartRef.current, config);
    };

    renderBarChart();

    return () => {
      if (barChartInstanceRef.current) {
        barChartInstanceRef.current.destroy();
      }
    };
  }, [data, activeTab]);

  useEffect(() => {
    const renderPieChart = () => {
      if (!pieChartRef.current || !data[activeTab] || !data[activeTab].length) {
        console.log("Skipping rendering pie chart");
        return;
      }

      if (pieChartInstanceRef.current) {
        pieChartInstanceRef.current.destroy();
      }

      const totalDebit = data[activeTab].reduce(
        (acc, entry) => acc + parseFloat(entry.debit),
        0
      );
      const totalCredit = data[activeTab].reduce(
        (acc, entry) => acc + parseFloat(entry.credit),
        0
      );
      const labels = ["Debit", "Credit"];
      const dataValues = [totalDebit, totalCredit];
      const percentages = dataValues.map((value) =>
        ((value / (totalDebit + totalCredit)) * 100).toFixed(2)
      );

      const config = {
        type: "pie",
        data: {
          labels: labels.map((label, index) => ` (${percentages[index]}%)`),
          datasets: [
            {
              data: dataValues,
              backgroundColor: ["#F97C4B", "#3D9189"],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      };

      pieChartInstanceRef.current = new Chart(pieChartRef.current, config);
    };

    renderPieChart();

    return () => {
      if (pieChartInstanceRef.current) {
        pieChartInstanceRef.current.destroy();
      }
    };
  }, [data, activeTab]);

  const toggleTableView = () => {
    setShowTableView(!showTableView);
    getTransGraph();
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#E9F0F7",
      borderColor: state.isFocused ? "#E9F0F7" : "#E9F0F7",
      boxShadow: "none",
      fontSize: "14px",
      color: "#194280",
      "&:hover": {
        borderColor: "#E9F0F7",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#D1D1D1"
        : state.isFocused
        ? "#D1D1D1"
        : null,
      color: state.isSelected ? "#194280" : "#194280",
      fontSize: "14px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#194280",
      fontSize: "14px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#7D868B",
    }),
    indicatorSeparator: () => ({ display: "none" }),
  };
  return (
    <>
      <div className="position-relative">
        <div
          className="mt-1"
          style={{ position: "absolute", width: "130px", right: "0" }}
        >
          <Select
            styles={customStyles}
            placeholder="Service"
            options={serviceOptions}
            value={selectedOption}
            onChange={setSelectedOption}
          />
        </div>
      </div>
      <div
        className="py-2 px-3 d-flex align-items-center"
        style={{
          backgroundColor: "#E9F0F7",
          borderRadius: "5px",
          width: "310px",
        }}
      >
        {["daily", "weekly", "monthly", "yearly"].map((tab) => (
          <button
            key={tab}
            className={`bg-transparent border-0 ${
              activeTab === tab ? "active-tab" : ""
            }`}
            style={{
              fontFamily: "League Spartan",
              fontWeight: "400",
              color: "rgba(125, 134, 139, 1)",
              fontSize: "14px",
              marginLeft: tab === "daily" ? "5px" : "20px",
            }}
            onClick={() => handleTabChange(tab)}
          >
            {t(tab.charAt(0).toUpperCase() + tab.slice(1))}
          </button>
        ))}
        {showTableView ? (
          <FaRegChartBar
            onClick={toggleTableView}
            style={{
              marginLeft: "20px",
              cursor: "pointer",
              fontSize: "20px",
              color: "#7D868B",
            }}
          />
        ) : (
          <FaTableList
            onClick={toggleTableView}
            style={{
              marginLeft: "20px",
              cursor: "pointer",
              fontSize: "20px",
              color: "#7D868B",
            }}
          />
        )}
      </div>

      <div
        className="mt-4"
        style={{
          position: "relative",
          height: "70%",
          overflowY: showTableView ? "scroll" : "hidden",
        }}
      >
        {showTableView ? (
          <div className="chart_table">
            {data[activeTab].length > 0 ? (
              <table
                style={{
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#F2F4F6",
                        color: "#4F575B",
                        border: "1px solid #dee2e6",
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      {activeTab === "weekly"
                        ? "Week"
                        : activeTab === "monthly"
                        ? "Month"
                        : activeTab === "yearly"
                        ? "Year"
                        : "Date"}
                    </th>
                    <th
                      style={{
                        backgroundColor: "#F2F4F6",
                        color: "#4F575B",
                        border: "1px solid #dee2e6",
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      {t("Debit")}
                    </th>
                    <th
                      style={{
                        backgroundColor: "#F2F4F6",
                        color: "#4F575B",
                        border: "1px solid #dee2e6",
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      {t("Credit")}
                    </th>
                  </tr>
                </thead>
                <tbody style={{ border: "none" }}>
                  {data[activeTab].map((entry, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          border: "1px solid #dee2e6",
                          padding: "8px",
                          textAlign: "left",
                        }}
                      >
                        {activeTab === "weekly"
                          ? entry.week
                          : entry.date || entry.month_year || entry.year}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dee2e6",
                          padding: "8px",
                          textAlign: "left",
                          color: "#F97C4B",
                        }}
                      >
                        {entry.debit}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dee2e6",
                          padding: "8px",
                          textAlign: "left",
                          color: "#3D9189",
                        }}
                      >
                        {entry.credit}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                <p
                  className="mb-0 mt-5 pt-3"
                  style={{ fontSize: "18px", color: "rgba(79, 87, 91, 1)" }}
                >
                  No data available
                </p>
              </div>
            )}
          </div>
        ) : (
          <>
            {data[activeTab].length > 0 ? (
              <canvas ref={barChartRef} />
            ) : (
              <div className="d-flex align-items-center justify-content-center h-100">
                <p
                  className="mb-0"
                  style={{ fontSize: "18px", color: "rgba(79, 87, 91, 1)" }}
                >
                  No data available
                </p>
              </div>
            )}

            <div
              className="bar_chart"
              // style={{
              //   position: "absolute",
              //   right: "0%",
              //   width: "60px",
              //   height: "60px",
              //   top: "0",
              // }}
            >
              {/* <canvas ref={pieChartRef} /> */}
            </div>
          </>
        )}
      </div>
      {data[activeTab].length > 0 && (
        <h2
          style={{
            fontWeight: "500",
            fontSize: "18px",
            color: "rgba(79, 87, 91, 1)",
          }}
          className="text-center mb-0"
        >
          {t("Statistics Overview")}
        </h2>
      )}
    </>
  );
};

export default MyChartComponent;
