import React, { useState, useEffect } from "react";
import { circles, mantraSuccess } from "../../../images";
import Header from "../../../components/Header";
import API from "../../../components/api2";
import { discoverAvdm, CaptureAvdm } from "../../../utils/rd";
import { aepsRegistration, aepsMerchantAuthenticity } from "../../../urls";
import Swal from "sweetalert2";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { changePage } from "../../../redux/slice/dashboardSlice";

const TwoFactorAuth = ({ aepsMobData, aepsAadharData }) => {
  const [rd, setRD] = useState();
  const [fingerData, setFingerData] = useState();
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [consent, setConsent] = useState(false);

  const location = useLocation();
  const bank_key = location.state?.bankKey || "";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  navigator.geolocation.getCurrentPosition(function (position) {
    setData((prev) => ({
      ...prev,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    }));
  });

  useEffect(() => {
    discoverAvdm().then((data) => {
      setRD(data);
    });
  }, []);

  const captureFingerData = (e) => {
    e.preventDefault();
    CaptureAvdm(rd).then((xml) => {
      setFingerData(xml);
      setShowModal(true);
    });
  };

  const submitForm = (e) => {
    e.preventDefault();

    API({
      ...aepsRegistration,
      body: {
        ...data,
        fingerdata: fingerData,
        aadhar: data.aadhar,
        mobile_number: aepsMobData,
        pipe: bank_key,
      },
      onSuccess: (res) => {
        setTimeout(() => {
          fetchMerchantAuthenticity();
        }, 1000);
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get("https://api64.ipify.org?format=json");
        const ipAddress = response.data.ip;
        setData((prev) => ({
          ...prev,
          ip: ipAddress,
        }));
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIp();
  }, []);

  const fetchMerchantAuthenticity = () => {
    API({
      ...aepsMerchantAuthenticity,
      body: {
        ...data,
        fingerdata: fingerData,
        mobile_number: aepsMobData,
        pipe: bank_key,
      },
      onSuccess: (res) => {
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          dispatch(
            changePage({
              page: "configureBanks",
            })
          );

          navigate("/");
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  return (
    <>
      <Header />
      <div className="my-2 bg-worker flex-grow-1 w-100 p-4 me-2 position-relative overflow-y-auto">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-3 mb-0"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1px solid rgba(217, 217, 217, 1)",
          }}
        >
          Two Factor Authentication
        </h2>

        <form className="col-5 mt-4 mx-auto">
          <label
            htmlFor="mobile_number"
            className="mb-0 login_text"
            style={{
              fontWeight: "500",
              fontFamily: "Montserrat",
              fontSize: "14px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Enter your mobile number
          </label>
          <input
            type="number"
            name="mobile_number"
            id="mobile_number"
            placeholder="+91"
            value={aepsMobData}
            className="p-2 w-100 mt-1 login_text"
            style={{
              backgroundColor: aepsMobData
                ? "white"
                : "rgba(255, 231, 159, 0.4)",
              borderRadius: "10px",
              fontFamily: "Montserrat",
              border: aepsMobData
                ? "1px solid #D1D8DF"
                : "1px solid rgba(255, 231, 159, 0.4)",
            }}
            readOnly
          />
          <p
            className="mb-0 ms-1 trans_text"
            style={{ fontSize: "12px", fontWeight: "500" }}
          >
            Note: Register with UIDAI number
          </p>
          <label
            htmlFor="aadhar"
            className="mb-0 mt-3 login_text"
            style={{
              fontWeight: "500",
              fontFamily: "Montserrat",
              fontSize: "14px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            Enter your Aadhar number
          </label>
          <input
            type="number"
            name="aadhar"
            id="aadhar"
            className="p-2 w-100 mt-1 login_text"
            value={data.aadhar}
            style={{
              backgroundColor: data.aadhar
                ? "white"
                : "rgba(255, 231, 159, 0.4)",
              borderRadius: "10px",
              fontFamily: "Montserrat",
              border: data.aadhar
                ? "1px solid #D1D8DF"
                : "1px solid rgba(255, 231, 159, 0.4)",
            }}
            onChange={(e) => {
              if (e.target.value.length <= 12)
                setData((prev) => ({
                  ...prev,
                  aadhar: e.target.value,
                }));
            }}
            // readOnly
          />
          <div className="mt-3 d-flex w-100">
            <input
              type="checkbox"
              id="consent"
              name="consent"
              style={{
                margin: "2px 10px 0 0",
              }}
              checked={consent}
              onChange={(e) => setConsent(e.target.checked)}
            />
            <label
              htmlFor="consent"
              className="fs_xl"
              style={{ fontSize: "15px", fontWeight: "500" }}
            >
              I am providing my consent to PrayasPe for using my aadhar number
              and fingerprint for aeps registration.
            </label>
          </div>

          <div className="text-center col-11 mx-auto mt-4 pt-2 position-relative">
            <button
              className="border-0 w-100 py-2 px-3 login_text"
              style={{
                backgroundColor: "rgba(22, 64, 127, 1)",
                borderRadius: "10px",
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: "500",
                opacity: consent && data.aadhar ? "1" : "0.5",
                cursor: consent && data.aadhar ? "pointer" : "not-allowed",
              }}
              disabled={!consent || data.aadhar === ""}
              onClick={
                rd ? (fingerData ? submitForm : captureFingerData) : null
              }
            >
              {fingerData ? "Continue" : "Capture"}
            </button>
            <Modal
              className="custom-modal"
              show={showModal}
              onHide={() => setShowModal(false)}
            >
              <Modal.Body className="text-center">
                <img
                  src={mantraSuccess}
                  alt="Mantra Success"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "49%",
                    transform: "translateY(55%)",
                  }}
                />
              </Modal.Body>
            </Modal>
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  aepsMobData: state.user.aepsMobile,
  aepsAadharData: state.user.aepsAadhar,
});

export default connect(mapStateToProps)(TwoFactorAuth);
