import React from "react";

const ImageViewer = ({
  src,
  alt = "",
  thumbnailWidth = "100%",
  thumbnailHeight = "",
}) => {
  const [zoom, setZoom] = React.useState(1);
  const [rotation, setRotation] = React.useState(0);
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleZoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.5);
  };

  const handleZoomOut = () => {
    if (zoom > 0.1) {
      setZoom((prevZoom) => prevZoom - 0.5);
    }
  };

  const handleRotate = () => {
    setRotation((prevRotation) => prevRotation + 90);
  };

  const handleReset = () => {
    setZoom(1);
    setRotation(0);
  };

  const handleImageClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(!modalOpen);
    handleReset();
  };

  const imageStyles = {
    width: "600px",
    maxHeight: "600px",
    transform: `scale(${zoom}) rotate(${rotation}deg)`,
    transition: "transform 0.3s",
  };

  const buttonStyles = {
    padding: "8px 10px",
    cursor: "pointer",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#333",
    color: "#fff",
  };
  const buttonContainerStyles = {
    display: "flex",
    position: "absolute",
    top: "15px",
    right: "48px",
    gap: "5px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "5px",
    borderRadius: "5px",
    zIndex: 2,
  };

  const modalStyles = {
    display: modalOpen ? "flex" : "none",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#000",
    zIndex: 1000,
    overflowY: "auto",
  };

  const modalCloseButtonStyles = {
    position: "absolute",
    top: "-5px",
    left: "100%",
    fontSize: "40px",
    color: "#f1f1f1",
    cursor: "pointer",
  };
  const thumbnailStyles = {
    width: thumbnailWidth,
    cursor: "pointer",
    pointerEvents: "all",
    padding: "6px 12px",
    height: thumbnailHeight,
  };

  return (
    <div>
      <div onClick={handleImageClick}>
        <img src={src} alt={alt} style={thumbnailStyles} />
      </div>
      <div style={modalStyles}>
        <div style={buttonContainerStyles}>
          <span
            className="mt-1 ms-1"
            style={modalCloseButtonStyles}
            onClick={handleCloseModal}
          >
            &times;
          </span>
          <button style={buttonStyles} onClick={handleZoomIn}>
            Zoom In
          </button>
          <button style={buttonStyles} onClick={handleZoomOut}>
            Zoom Out
          </button>
          <button style={buttonStyles} onClick={handleRotate}>
            Rotate
          </button>
          <button style={buttonStyles} onClick={handleReset}>
            Reset
          </button>
        </div>

        <div>
          <img src={src} alt={alt} style={imageStyles} />
        </div>
      </div>
    </div>
  );
};

export default ImageViewer;
