import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import API from "../components/api2";
import { transactionHistory } from "../urls";
import { noImage2 } from "../images";
import { useTranslation } from "react-i18next";

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;
  const { t } = useTranslation();

  const fetchTransactions = (offset) => {
    API({
      ...transactionHistory,
      params: { offset, limit },
      onSuccess: (res) => {
        const newTransactions = res.data.data.table_data;
        const totalRows = res.data.data.total_rows;

        const existingIds = new Set(transactions.map((t) => t.transaction_id));

        const filteredTransactions = newTransactions.filter(
          (t) => !existingIds.has(t.transaction_id)
        );

        setTransactions((prevTransactions) => [
          ...prevTransactions,
          ...filteredTransactions,
        ]);

        if (offset + limit >= totalRows) {
          setHasMore(false);
        }
      },
      onError: (error) => {
        console.error("Error fetching transactions:", error);
      },
    });
  };

  useEffect(() => {
    fetchTransactions(offset);
  }, []);

  const fetchMoreTransactions = () => {
    const newOffset = offset + limit;
    setOffset(newOffset);
    fetchTransactions(newOffset);
  };
  const formatTransactionDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${day}-${month}-${year}, ${hours}:${minutes}`;
  };

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    if (scrollTop === 0) {
      setTransactions([]);
      setOffset(0);
      setHasMore(true);
      fetchTransactions(0);
    }
  };

  return (
    <section className="h-100">
      <div className="container-fluid h-100">
        <p
          className="mb-0 ms-4 login_text"
          style={{
            fontWeight: "500",
            fontSize: "20px",
            color: "rgba(79, 87, 91, 1)",
          }}
        >
          {t("Transaction")}
        </p>
        <div
          id="scrollData"
          onScroll={handleScroll}
          style={{ overflowY: "auto", height: "90%" }}
        >
          <InfiniteScroll
            dataLength={transactions.length}
            next={fetchMoreTransactions}
            hasMore={hasMore}
            scrollableTarget={"scrollData"}
            endMessage={
              <p
                className="mt-3 mb-0 text-center"
                style={{
                  fontWeight: "500",
                  color: "rgba(79, 87, 91, 1)",
                }}
              >
                No more transactions to show
              </p>
            }
          >
            {transactions.length > 0 ? (
              transactions.map((t) => (
                <div className="container-fluid" key={t.transaction_id}>
                  <div className="row d-flex justify-content-between align-items-center mt-4">
                    <div className="col-9">
                      <div className="d-flex align-items-center">
                        <img
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50%",
                            border: "1.5px solid black",
                          }}
                          src={t.photo ? t.photo : noImage2}
                          alt={`trans${t.transaction_id}`}
                        />
                        <div className="ms-2">
                          <p
                            style={{
                              fontFamily: "League Spartan",
                              fontSize: "15px",
                              color: "rgba(79, 87, 91, 1)",
                              fontWeight: "400",
                            }}
                            className="mb-0 trans_text"
                          >
                            {t.full_name}
                          </p>
                          <p
                            style={{
                              fontFamily: "League Spartan",
                              fontSize: "12px",
                              color: "rgba(79, 87, 91, 1)",
                              fontWeight: "400",
                            }}
                            className="mb-0 ms-1 trans_text"
                          >
                            {formatTransactionDate(t.transaction_date)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div>
                        <p
                          style={{
                            fontFamily: "League Spartan",
                            fontSize: "13px",
                            color: "#3D9189",
                            fontWeight: "400",
                          }}
                          className="mb-0 me-5 trans_text"
                        >
                          {t.credit_amount}
                        </p>
                        <p
                          style={{
                            fontFamily: "League Spartan",
                            fontSize: "13px",
                            color: "#F97C4B",
                            fontWeight: "400",
                          }}
                          className="mb-0 me-5 trans_text"
                        >
                          {t.debit_amount}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p
                className="mb-0 mt-5 pt-5 text-center"
                style={{ fontSize: "22px", color: "rgba(79, 87, 91, 1)" }}
              >
                No transactions found.
              </p>
            )}
          </InfiniteScroll>
        </div>
      </div>
    </section>
  );
};

export default TransactionHistory;
