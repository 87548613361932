import React, { useEffect, useState } from "react";
import {
  changePage,
  setscrollToHighlight,
  setscrollToId,
} from "../redux/slice/dashboardSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import { newLogo, searchIcon, sideNavCircles } from "../images";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getProfile } from "../urls";
import API from "./api2";

const SideNavbar = ({ username, menusList }) => {
  const dispatch = useDispatch();
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [empType, setEmpType] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectedServiceId = useSelector((state) => state.dashboard.scrollToId);
  const selectedServiceIdHighlight = useSelector(
    (state) => state.dashboard.scrollToHighlight
  );

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  const handleAccordionToggle = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const changePageFunc = (e, service) => {
    let url;

    if (e.target.id) {
      url = e.target.id;
      dispatch(
        changePage({
          page: url,
        })
      );
    } else if (service) {
      url = service.id;
      dispatch(
        changePage({
          page: "services",
          service: url,
        })
      );
      dispatch(setscrollToId(service.id));
      dispatch(setscrollToHighlight(service.id));
    }

    navigate("/");
  };
  const homeDash = () => {
    dispatch(
      changePage({
        page: "home",
      })
    );

    navigate("/");
  };

  const services = [
    {
      name: "Bank Correspondence Services",
      id: "bankCorrespondenceService",
    },
    {
      name: "Aadhar Enabled Payment Services (AEPS)",
      id: "aadharEnablePayment",
    },
    { name: "Micro Atm", id: "microATM" },
    { name: "Domestic Money Transfer", id: "domesticMoneyTransfer" },
    { name: "Cash Management Services", id: "cashManagement" },
    { name: "Bharat Bill Payment Services", id: "bharatBillPayment" },
    { name: "Travel Services", id: "travelServices" },
    { name: "Financial Services", id: "financialServices" },
    { name: "Government Services", id: "governmentServices" },
    { name: "Recharge Services", id: "rechargeServices" },
    { name: "Device Sales", id: "devicesSales" },
  ];
  const endCustomer = [
    {
      name: t("New End Customer"),
      id: "newEndCustomer",
    },
    { name: t("All End Customers"), id: "allEndCustomers" },
  ];

  const endCustomerVariations = {
    "New End Customer": "newEndCustomer",
    "Add End Customer": "newEndCustomer",
    "All End Customers": "allEndCustomers",
    "All End Customer": "allEndCustomers",
    "View All End Customers": "allEndCustomers",
    "View All End Customer": "allEndCustomers",
  };

  const findMatchingOptionId = (menuName) => {
    for (const [key, value] of Object.entries(endCustomerVariations)) {
      if (menuName.toLowerCase().includes(key.toLowerCase())) {
        return value;
      }
    }
    return null;
  };

  const availableEndCustomerMenus = endCustomer.filter((option) =>
    menusList.some((menuItem) => {
      const matchesOptionId =
        findMatchingOptionId(menuItem.menu_name) === option.id;
      const matchesSearchTerm = menuItem.menu_name
        .toLowerCase()
        .includes(lowerCaseSearchTerm);

      return matchesOptionId && matchesSearchTerm;
    })
  );
  const workFlow = [
    {
      name: t("Add Workflow"),
      id: "addWorkflow",
    },
    { name: t("All Workflows"), id: "allWorkflows" },
    { name: t("All Tickets"), id: "allTickets" },
  ];

  const workflowVariations = {
    "New Workflow": "addWorkflow",
    "Add Workflow": "addWorkflow",
    "Create Workflow": "addWorkflow",
    "All Workflows": "allWorkflows",
    "All Workflow": "allWorkflows",
    "All Tickets": "allTickets",
    "All Ticket": "allTickets",
  };
  const findMatchingWorkflow = (menuName) => {
    for (const [key, value] of Object.entries(workflowVariations)) {
      if (menuName.toLowerCase().includes(key.toLowerCase())) {
        return value;
      }
    }
    return null;
  };
  const availableHelpdeskMenus = workFlow.filter((option) =>
    menusList.some((menuItem) => {
      const matchesWorkflow =
        findMatchingWorkflow(menuItem.menu_name) === option.id;
      const matchesSearchTerm = menuItem.menu_name
        .toLowerCase()
        .includes(lowerCaseSearchTerm);

      return matchesWorkflow && matchesSearchTerm;
    })
  );

  const reports = [
    {
      name: t("Platform Transactions"),
      id: "platformTransactions",
    },
    { name: t("Brand Transactions"), id: "brandTransactions" },
    { name: t("Merchant Transactions"), id: "merchantTransactions" },
  ];

  const filterReports = (empType, searchTerm) => {
    switch (empType) {
      case "PLATFORM":
        return reports.filter((report) =>
          report.name.toLowerCase().includes(lowerCaseSearchTerm)
        );
      case "BRAND":
        return reports
          .filter((report) => report.id !== "platformTransactions")
          .filter((report) =>
            report.name.toLowerCase().includes(lowerCaseSearchTerm)
          );
      case "MERCHANT":
        return reports
          .filter((report) => report.id === "merchantTransactions")
          .filter((report) =>
            report.name.toLowerCase().includes(lowerCaseSearchTerm)
          );
      default:
        return [];
    }
  };

  const filteredReports = filterReports(empType, searchTerm);

  const fetchEmpType = () => {
    API({
      ...getProfile,
      params: { employee_code: username },
      onSuccess: (res) => {
        setEmpType(res.data.data.employee_type);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };
  useEffect(() => {
    fetchEmpType();
  }, []);

  // filter menus based on available menus
  const availableMenuNames = menusList.map((menu) =>
    menu.menu_name.toLowerCase()
  );

  const availableServices = services.filter((service) => {
    const serviceNameMatches = availableMenuNames.some((menuName) =>
      service.name.toLowerCase().includes(menuName)
    );
    const searchTermMatches = service.name
      .toLowerCase()
      .includes(lowerCaseSearchTerm);

    return serviceNameMatches && searchTermMatches;
  });

  const groupMenusByHeader = (searchTerm) => {
    const headers = {
      "Manage Brand": [],
      "Manage Employee": [],
      "Manage Security": [],
      "Manage Merchant": [],
      "Manage Services": [],
      "Manage API Services": [],
      "Manage Business": [],
    };

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    menusList.forEach((menu) => {
      const menuNameIncludesSearchTerm = menu.menu_name
        .toLowerCase()
        .includes(lowerCaseSearchTerm);

      if (
        menuNameIncludesSearchTerm &&
        menu.menu_name.includes("Brand") &&
        !menu.menu_name.includes("Transactions")
      ) {
        headers["Manage Brand"].push({
          ...menu,
          id: menu.menu_name.includes(t("Create"))
            ? "addBusinessBrand"
            : "updateBusinessBrand",
        });
      } else if (
        menuNameIncludesSearchTerm &&
        (menu.menu_name.includes("Employee") ||
          menu.menu_name.includes("Reset"))
      ) {
        if (menu.menu_name.includes("Create")) {
          headers["Manage Employee"].push({ ...menu, id: "newEmployee" });
        } else if (menu.menu_name.includes("All")) {
          headers["Manage Employee"].push({ ...menu, id: "updateEmployee" });
        } else if (menu.menu_name.includes("Assign")) {
          headers["Manage Employee"].push({ ...menu, id: "allEmployeeRights" });
        } else if (menu.menu_name.includes("Reset Password")) {
          headers["Manage Employee"].push({ ...menu, id: "adminReset" });
        }
      } else if (
        menuNameIncludesSearchTerm &&
        (menu.menu_name.includes("Menu") || menu.menu_name.includes("Roles"))
      ) {
        if (menu.menu_name.includes("Add")) {
          headers["Manage Security"].push({ ...menu, id: "newMenu" });
        } else if (menu.menu_name.includes("Manage Menu")) {
          headers["Manage Security"].push({ ...menu, id: "menuList" });
        } else if (menu.menu_name.includes("Roles")) {
          headers["Manage Security"].push({ ...menu, id: "manageRoles" });
        }
      } else if (
        menuNameIncludesSearchTerm &&
        menu.menu_name.includes("Merchant")
      ) {
        if (menu.menu_name.includes("New")) {
          headers["Manage Merchant"].push({ ...menu, id: "newMerchant" });
        } else if (menu.menu_name.includes("All Applications")) {
          headers["Manage Merchant"].push({
            ...menu,
            id: "allMerchantApplication",
          });
        } else if (menu.menu_name.includes("All Merchants")) {
          headers["Manage Merchant"].push({ ...menu, id: "allMerchants" });
        } else if (menu.menu_name.includes("Recharge")) {
          headers["Manage Merchant"].push({ ...menu, id: "rechargeMerchant" });
        }
      } else if (
        menuNameIncludesSearchTerm &&
        menu.menu_name.includes("Service")
      ) {
        if (menu.menu_name.includes("Standard")) {
          headers["Manage Services"].push({
            ...menu,
            id: "allStandardServices",
          });
        } else if (menu.menu_name.includes("Providers")) {
          headers["Manage Services"].push({
            ...menu,
            id: "allServiceProviders",
          });
        }
      } else if (
        menuNameIncludesSearchTerm &&
        (menu.menu_name.includes("API & Portal Utility") ||
          menu.menu_name.includes("Consumer") ||
          menu.menu_name.includes("Commission"))
      ) {
        if (menu.menu_name.includes("API & Portal Utility")) {
          headers["Manage API Services"].push({ ...menu, id: "getApiPortal" });
        } else if (menu.menu_name.includes("Consumer")) {
          headers["Manage API Services"].push({ ...menu, id: "getConsumer" });
        } else if (menu.menu_name.includes("Commission")) {
          headers["Manage API Services"].push({
            ...menu,
            id: "getCommissions",
          });
        }
      } else if (
        menuNameIncludesSearchTerm &&
        menu.menu_name.includes("Business")
      ) {
        if (menu.menu_name.includes("Create")) {
          headers["Manage Business"].push({ ...menu, id: "newBusinessPlan" });
        } else if (
          menu.menu_name.includes("All") &&
          !menu.menu_name.includes("Allot Business Scheme")
        ) {
          headers["Manage Business"].push({
            ...menu,
            id: "allBusinessScheme",
          });
        } else if (menu.menu_name.includes("Recharge")) {
          headers["Manage Business"].push({
            ...menu,
            id: "rechargeMyWallet",
          });
        } else if (menu.menu_name.includes("Transfer")) {
          headers["Manage Business"].push({
            ...menu,
            id: "transferMoneyToBank",
          });
        }
      }
    });
    return headers;
  };

  const groupedMenus = groupMenusByHeader(searchTerm);

  // Filter out headers with no menus
  const filteredGroupedMenus = Object.entries(groupedMenus).filter(
    ([, menus]) => menus.length > 0
  );

  return (
    <section className="mx-2 d-flex flex-column min-vh-100">
      <div
        className="px-3 py-2 position-relative"
        style={{
          backgroundColor: "#D9D9D9",
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        <img
          className="position-absolute"
          style={{ right: "0px", top: "0px" }}
          src={sideNavCircles}
          alt="circles"
        />
        <img
          onClick={homeDash}
          style={{
            maxWidth: "160px",
            cursor: "pointer",
            pointerEvents: "auto",
          }}
          src={newLogo}
          alt="logo"
        />
      </div>
      <div
        className="py-3 flex-grow-1 overflow-y-scroll"
        style={{ backgroundColor: "#194280", height: "250px" }}
      >
        <div
          style={{ backgroundColor: "white", borderRadius: "20px" }}
          className="py-1 px-3 mx-4"
        >
          <img className="me-2" src={searchIcon} alt="search-icon" />

          <input
            placeholder="Search"
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            style={{
              border: "none",
              outline: "none",
              flex: 1,
            }}
          />
        </div>
        <h2
          style={{
            fontWeight: "500",
            fontSize: "20px",
            borderBottom: "1.5px solid rgba(255, 255, 255, 1)",
            cursor: "pointer",
          }}
          className="mt-3 pb-1 text-center text-white mb-0 mx-4"
          onClick={homeDash}
        >
          {t("Dashboard")}
        </h2>
        <Accordion className="mt-4 custom-accordion2">
          {/* admin  */}
          {filteredGroupedMenus && filteredGroupedMenus.length > 0 && (
            <Accordion.Item
              className="mt-2"
              eventKey={0}
              onClick={() => handleAccordionToggle(0)}
              style={{ backgroundColor: "transparent" }}
            >
              <Accordion.Header
                className="custom-accordion-admin"
                style={{ justifyContent: "center", color: "white" }}
              >
                {t("Administrator")}
              </Accordion.Header>
              <Accordion.Body className="overflow-y-auto">
                {filteredGroupedMenus.map(([header, menus], index) => (
                  <Accordion className="child-accordion" key={index}>
                    <Accordion.Item eventKey={index.toString()}>
                      <Accordion.Header>{t(header)}</Accordion.Header>
                      <Accordion.Body className="overflow-y-auto">
                        {menus.map((item) => (
                          <div
                            key={item.id}
                            className="px-4 py-2 d-flex justify-content-center admin-hvr"
                            style={{
                              backgroundColor: "#20314F",
                            }}
                          >
                            <p
                              style={{ fontSize: "13px", cursor: "pointer" }}
                              className="mb-0 text-white fs_xl"
                              id={item.id}
                              onClick={(e) => changePageFunc(e, item.id)}
                            >
                              {t(item.menu_name)}
                            </p>
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          )}

          {/* reports  */}
          {filteredReports && filteredReports.length > 0 && (
            <Accordion.Item
              className="mt-4 custom-accordion2"
              eventKey={1}
              onClick={() => handleAccordionToggle(1)}
              style={{ backgroundColor: "transparent" }}
            >
              <Accordion.Header
                className="custom-accordion-admin"
                style={{ justifyContent: "center", color: "white" }}
              >
                {t("Reports")}
              </Accordion.Header>
              <Accordion.Body className="overflow-y-auto">
                {filteredReports.map((r, index) => {
                  return (
                    <div
                      key={index}
                      className="px-4 py-2 services-hvr d-flex justify-content-center"
                      style={{
                        backgroundColor: "#063e70",
                      }}
                    >
                      <p
                        style={{ fontSize: "13px", cursor: "pointer" }}
                        className="mb-0 text-white fs_xl"
                        id={r.id}
                        onClick={(e) => changePageFunc(e, r)}
                      >
                        {r.name}
                      </p>
                    </div>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
          )}

          {/* services  */}
          {empType === "MERCHANT" &&
            availableServices &&
            availableServices.length > 0 && (
              <Accordion.Item
                className="mt-4"
                eventKey={2}
                onClick={() => handleAccordionToggle(2)}
              >
                <Accordion.Item style={{ backgroundColor: "transparent" }}>
                  <Accordion.Header
                    className="custom-accordion-admin"
                    style={{ justifyContent: "center", color: "white" }}
                  >
                    {t("Services")}
                  </Accordion.Header>
                  <Accordion.Body className="overflow-y-auto">
                    {availableServices && availableServices.length > 0 ? (
                      availableServices.map((service, index) => {
                        return (
                          <div
                            key={index}
                            className={`px-4 py-2 services-hvr d-flex justify-content-center ${
                              selectedServiceIdHighlight === service.id
                                ? "active-service"
                                : ""
                            }`}
                            style={{
                              backgroundColor: "#063e70",
                            }}
                          >
                            <p
                              style={{ fontSize: "13px", cursor: "pointer" }}
                              className={`mb-0 text-white fs_xl`}
                              onClick={(e) => changePageFunc(e, service)}
                            >
                              {t(service.name)}
                            </p>
                          </div>
                        );
                      })
                    ) : (
                      <div className="no-services">
                        <p>No services available</p>
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion.Item>
            )}

          {/* end customer  */}
          {empType === "MERCHANT" &&
            availableEndCustomerMenus &&
            availableEndCustomerMenus.length > 0 && (
              <Accordion.Item
                className="mt-4 custom-accordion2"
                eventKey={3}
                onClick={() => handleAccordionToggle(3)}
                style={{ backgroundColor: "transparent" }}
              >
                <Accordion.Header
                  className="custom-accordion-admin"
                  style={{ justifyContent: "center", color: "white" }}
                >
                  {t("End Customer")}
                </Accordion.Header>
                <Accordion.Body className="overflow-y-auto">
                  {availableEndCustomerMenus.map((end, index) => {
                    return (
                      <div
                        key={index}
                        className="px-4 py-2 services-hvr d-flex justify-content-center"
                        style={{
                          backgroundColor: "#063e70",
                        }}
                      >
                        <p
                          style={{ fontSize: "13px", cursor: "pointer" }}
                          className="mb-0 text-white fs_xl"
                          id={end.id}
                          onClick={(e) => changePageFunc(e, end)}
                        >
                          {end.name}
                        </p>
                      </div>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            )}

          {/* helpdesk  */}
          {availableHelpdeskMenus && availableHelpdeskMenus.length > 0 && (
            <Accordion.Item
              className="mt-4 custom-accordion2"
              eventKey={4}
              onClick={() => handleAccordionToggle(4)}
              style={{ backgroundColor: "transparent" }}
            >
              <Accordion.Header
                className="custom-accordion-admin"
                style={{ justifyContent: "center", color: "white" }}
              >
                {t("Helpdesk")}
              </Accordion.Header>
              <Accordion.Body className="overflow-y-auto">
                {availableHelpdeskMenus.map((w, index) => {
                  return (
                    <div
                      key={index}
                      className="px-4 py-2 services-hvr d-flex justify-content-center"
                      style={{
                        backgroundColor: "#063e70",
                      }}
                    >
                      <p
                        style={{ fontSize: "13px", cursor: "pointer" }}
                        className="mb-0 text-white fs_xl"
                        id={w.id}
                        onClick={(e) => changePageFunc(e, w)}
                      >
                        {w.name}
                      </p>
                    </div>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
          )}
        </Accordion>
      </div>
    </section>
  );
};
const mapStateToProps = (state) => ({
  username: state.user.username,
  menusList: state.user.menus,
});

export default connect(mapStateToProps)(SideNavbar);
