import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { durationIcon, filterIcon } from "../../../../images";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import SelectSeats from "./SelectSeats";

const styles = {
  card: {
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "19px",
    padding: "1rem",
    marginTop: "1rem",
  },
  title: {
    fontWeight: "600",
    color: "#023685",
    fontSize: "15px",
  },
  text: {
    fontSize: "14px",
    fontWeight: "500",
  },
  priceText: {
    fontSize: "15px",
    fontWeight: "600",
  },
  seatsInfo: {
    fontSize: "14px",
    fontWeight: "500",
    color: "red",
  },
  singleSeats: {
    fontSize: "15px",
    fontWeight: "500",
  },
  border: {
    borderLeft: "1.5px solid #A3A3A3",
    borderRight: "1.5px solid #A3A3A3",
  },
  textCenter: {
    textAlign: "center",
  },
  container: {
    height: "calc(100vh - 360px)",
    overflowY: "auto",
  },
  rowMargin: {
    marginTop: "1rem",
  },
};

const AvailableTrips = ({
  trips,
  departureDay,
  setShowSelectSeats,
  showSelectSeats,
}) => {
  const [selectedBusType, setSelectedBusType] = useState([]);
  const [selectedSeating, setSelectedSeating] = useState([]);
  const [selectedPickupTime, setSelectedPickupTime] = useState("");
  const [selectedTripId, setSelectedTripId] = useState(null);

  const handleSeatingChange = (value) => {
    setSelectedSeating(value === selectedSeating ? "" : value);
  };
  const handleBusTypeChange = (event) => {
    setSelectedBusType(event.target.value);
  };

  const handlePickupTimeChange = (event) => {
    setSelectedPickupTime(event.target.value);
  };
  const clearFilters = () => {
    setSelectedBusType("");
    setSelectedSeating("");
    setSelectedPickupTime("");
  };

  const filterTrips = () => {
    return trips.filter((trip) => {
      const tripTime = new Date(trip.serviceStartTime);
      const tripHours = tripTime.getHours();

      const busTypeMatch =
        selectedBusType.length === 0 ||
        selectedBusType.includes(trip.busType.toLowerCase());

      const seatingMatch =
        selectedSeating.length === 0 ||
        selectedSeating.includes(trip.seatingType.toLowerCase());

      const pickupTimeMatch =
        selectedPickupTime === "" ||
        (selectedPickupTime === "morning" && tripHours < 5) ||
        (selectedPickupTime === "afternoon" &&
          tripHours >= 5 &&
          tripHours < 18) ||
        (selectedPickupTime === "evening" && tripHours >= 18);

      return busTypeMatch && seatingMatch && pickupTimeMatch;
    });
  };

  const filteredTrips = filterTrips();

  const convertMinutesToTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMins = mins.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMins} ${period}`;
  };

  const formatDepartureDay = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date
      .toLocaleString("default", { month: "short" })
      .toUpperCase();
    return `${day} ${month}`;
  };

  // handle select seats
  const handleSelectSeats = (id) => {
    setSelectedTripId(id);
    setShowSelectSeats(true);
  };

  if (showSelectSeats && selectedTripId) {
    return <SelectSeats tripId={selectedTripId} />;
  }

  return (
    <div className="row mt-2">
      <div className="col-3">
        <Accordion className="custom-accordion-filter" defaultActiveKey="0">
          <Accordion.Item
            eventKey={0}
            style={{ backgroundColor: "transparent" }}
          >
            <Accordion.Header className="custom-accordion-filter">
              <img
                style={{ width: "18px" }}
                className="me-2"
                src={filterIcon}
                alt="filter"
              />
              {"Filters"}
              <MdKeyboardDoubleArrowDown
                className="me-5"
                style={{ fontSize: "18px" }}
              />
              <button
                style={{
                  backgroundColor: "#023685",
                  color: "white",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
                className="border-0 ms-4"
              >
                Clear all
              </button>
            </Accordion.Header>
            <Accordion.Body
              style={{
                height: "calc(100vh - 410px)",
                border: "1px solid #D9D9D9",
                borderRadius: "5px",
              }}
              className="overflow-y-auto"
            >
              <div className="p-3">
                <h2 style={{ fontSize: "15px", fontWeight: "600" }}>
                  Bus Type
                </h2>
                <div className="row align-items-center">
                  <div className="col-6">
                    <label>
                      <input
                        type="checkbox"
                        value="a/c"
                        className="me-2"
                        checked={selectedBusType.includes("ac")}
                        onChange={handleBusTypeChange}
                      />
                      AC
                    </label>
                  </div>
                  <div className="col-6">
                    <label>
                      <input
                        type="checkbox"
                        value="non-a/c"
                        className="me-2"
                        checked={selectedBusType.includes("non-ac")}
                        onChange={handleBusTypeChange}
                      />
                      Non-AC
                    </label>
                  </div>
                </div>
                <h2
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    marginTop: "1rem",
                  }}
                >
                  Seating Type
                </h2>
                <div className="row align-items-center">
                  <div className="col-6">
                    <label>
                      <input
                        type="checkbox"
                        value="seater"
                        className="me-2"
                        checked={selectedSeating.includes("seater")}
                        onChange={handleSeatingChange}
                      />
                      Seater
                    </label>
                  </div>
                  <div className="col-6">
                    <label>
                      <input
                        type="checkbox"
                        value="sleeper"
                        className="me-2"
                        checked={selectedSeating.includes("sleeper")}
                        onChange={handleSeatingChange}
                      />
                      Sleeper
                    </label>
                  </div>
                  <div className="col-6">
                    <label>
                      <input
                        type="checkbox"
                        value="recliner"
                        className="me-2"
                        checked={selectedSeating.includes("recliner")}
                        onChange={handleSeatingChange}
                      />
                      Recliner
                    </label>
                  </div>
                </div>
                <div className="mt-2">
                  <p style={{ fontWeight: "500" }} className="mb-1">
                    Single window seater/sleeper
                  </p>
                  <label>
                    <input
                      type="checkbox"
                      value="single seater"
                      className="me-2"
                      checked={selectedSeating.includes("single seater")}
                      onChange={handleSeatingChange}
                    />
                    Single Seater
                  </label>
                </div>
              </div>
              <div>
                <label>
                  Pickup Time:
                  <select
                    onChange={handlePickupTimeChange}
                    value={selectedPickupTime}
                  >
                    <option value="">All</option>
                    <option value="morning">Before 5 AM</option>
                    <option value="afternoon">12 PM - 5 PM</option>
                    <option value="evening">After 6 PM</option>
                  </select>
                </label>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="col-9">
        <div style={styles.container}>
          {filteredTrips?.map((trip, index) => {
            return (
              <div key={index} style={styles.card}>
                <h2 style={styles.title}>{trip.travels}</h2>
                <div className="d-flex align-items-center justify-content-between">
                  <p
                    className="mb-0"
                    style={{ ...styles.text, width: "320px" }}
                  >
                    {trip.busType}
                  </p>
                  <div style={styles.textCenter}>
                    <p className="mb-0" style={styles.text}>
                      {Array.isArray(trip.boardingTimes)
                        ? trip.boardingTimes.length > 0
                          ? convertMinutesToTime(trip.boardingTimes[0].time)
                          : "No boarding time available"
                        : convertMinutesToTime(trip.boardingTimes?.time || 0)}
                    </p>
                    <p className="mb-0" style={styles.text}>
                      {formatDepartureDay(departureDay)}
                    </p>
                  </div>
                  <div className="mx-2" style={styles.textCenter}>
                    <img src={durationIcon} alt="duration" />
                    <p
                      className="mb-0 mt-1"
                      style={{ ...styles.text, fontWeight: "700" }}
                    >
                      {trip.duration}
                    </p>
                  </div>
                  <p className="mb-0" style={styles.text}>
                    {Array.isArray(trip.droppingTimes)
                      ? trip.droppingTimes.length > 0
                        ? convertMinutesToTime(
                            trip.droppingTimes[trip.droppingTimes.length - 1]
                              .time
                          )
                        : "No dropping time available"
                      : convertMinutesToTime(trip.droppingTimes?.time || 0)}
                  </p>

                  <div
                    style={{ ...styles.textCenter, ...styles.border }}
                    className="px-3 mx-3"
                  >
                    <p className="mb-1" style={styles.seatsInfo}>
                      {trip.availableSingleSeat} single seats left
                    </p>
                    <p className="mb-0" style={styles.singleSeats}>
                      {trip.availableSeats} seats left
                    </p>
                  </div>
                  <div style={{ ...styles.textCenter }}>
                    <p className="mb-1" style={styles.priceText}>
                      ₹ {trip.fares}
                    </p>
                    <button
                      className="text-white border-0 px-3 py-1"
                      onClick={() => handleSelectSeats(trip.id)}
                      style={{
                        backgroundColor: "#063E70",
                        borderRadius: "6px",
                        fontSize: "15px",
                      }}
                    >
                      Select Seats
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AvailableTrips;
