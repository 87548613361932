import React, { useEffect, useState } from "react";
import Select from "react-select";
import API from "../components/api2";
import {
  newEmployee,
  uniqueEmployee,
  brandDropdown,
  prayasPlatform,
  designation,
  profileRole,
  getProfile,
} from "../urls";
import Button from "../components/Button";
import Header from "../components/Header";
import { circles } from "../images";
import { useLocation, useNavigate } from "react-router-dom";
import InputForm from "../components/InputForm";
import Swal from "sweetalert2";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";

const NewEmployee = () => {
  const defaultPageData = {
    email_id: "",
    contact_no: "",
    employee_type: "",
    reference_id: "",
    employee_name: "",
    employee_code: "",
    profile_role: "",
    designation: "",
  };

  const [pageData, setPageData] = useState(defaultPageData);
  const [uniqueEmployeeData, setUniqueEmployeeData] = useState(null);
  const location = useLocation();
  const { username } = location.state || {};
  const [referenceOptions, setReferenceOptions] = useState([]);
  const [selectedEmployeeType, setSelectedEmployeeType] = useState(null);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [profileOptions, setProfileOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [employeeType, setEmployeeType] = useState([]);
  const [brandId, setBrandId] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedEmployeeType === "BRAND") {
      API({
        ...brandDropdown,
        onSuccess: (res) => {
          const brandOptions = res.data.data.map((brand) => ({
            value: brand.brand_id,
            label: `${brand.brand_id}-${brand.business_name}`,
          }));

          const filteredBrandOptions = brandId
            ? brandOptions.filter((brand) => brand.value === brandId)
            : brandOptions;

          setReferenceOptions(filteredBrandOptions);
          setPageData((prevData) => ({
            ...prevData,
            reference_id: "",
          }));
        },
      });
    } else if (selectedEmployeeType === "PLATFORM") {
      API({
        ...prayasPlatform,
        onSuccess: (res) => {
          const platformOption = res.data.data.map((p) => ({
            value: p.platform_id,
            label: `${p.platform_id}-${p.legal_name}`,
          }));
          setReferenceOptions(platformOption);
          setPageData((prevData) => ({
            ...prevData,
            reference_id: "",
          }));
        },
      });
    }
  }, [selectedEmployeeType, brandId]);

  useEffect(() => {
    API({
      ...designation,
      onSuccess: (res) => {
        const desig = res.data.data.map((d) => ({
          value: d.designation_id,
          label: `${d.designation}`,
        }));
        setDesignationOptions(desig);
      },
    });
  }, []);
  useEffect(() => {
    API({
      ...profileRole,
      onSuccess: (res) => {
        const profile = res.data.data.map((p) => ({
          value: p.profile_id,
          label: `${p.profile_role_id}`,
        }));
        setProfileOptions(profile);
      },
    });
  }, []);

  useEffect(() => {
    API({
      ...uniqueEmployee,
      onSuccess: (res) => {
        console.log("Unique Employee data fetched successfully");
        setUniqueEmployeeData(res.data.data);
      },
      onError: (error) => {
        console.log("Error fetching unique employee data");
      },
    });
  }, [username]);

  function pageDataFunc(event, selectedOption) {
    if (event && event.target) {
      const { id, value } = event.target;

      if (
        id === "email_id" ||
        id === "contact_no" ||
        id === "employee_code" ||
        id === "employee_name"
      ) {
        setPageData((prevData) => ({
          ...prevData,
          [id]: event.target.value,
        }));

        if (!event.target.value.trim()) {
          setUniqueEmployeeData((prevData) => ({
            ...prevData,
            [id]: null,
          }));
        } else {
          if (
            uniqueEmployeeData &&
            event.target.value !== uniqueEmployeeData[id]
          ) {
            checkUniqueEmployee(id, value);
          }
        }
      }
    } else if (selectedOption) {
      const { name, value } = selectedOption;
      setPageData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  }

  const checkUniqueEmployee = (field, value) => {
    if (!value.trim()) {
      setUniqueEmployeeData((prevData) => ({
        ...prevData,
        [field]: null,
      }));
      return;
    }

    API({
      body: { [field]: value },
      ...uniqueEmployee,
      onSuccess: (res) => {
        setUniqueEmployeeData((prevData) => ({
          ...prevData,
          [field]: {
            unique: true,
            message: `${res.data.message} `,
          },
        }));
      },
      onError: (error) => {
        setUniqueEmployeeData((prevData) => ({
          ...prevData,
          [field]: {
            unique: false,
            message: `${error.response.data.message}`,
          },
        }));
      },
    });
  };

  // vallidation
  const validationRules = {
    email_id: "email id",
    contact_no: "contact no",
    employee_type: "employee type",
    reference_id: "reference id",
    employee_name: "employee name",
    employee_code: "employee code",
    profile_role: "profile role",
    designation: "designation",
  };

  const validateForm = () => {
    const newErrors = {};

    Object.entries(validationRules).forEach(([key, label]) => {
      if (!pageData[key]) {
        newErrors[key] = `${label} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  function onSubmit(e) {
    e.preventDefault();
    if (validateForm()) {
      API({
        ...newEmployee,
        body: {
          ...pageData,
          designation_id: pageData.designation_id,
          designation: pageData.designation,
          profile_role_id: pageData.profile_id,
          profile_role: pageData.profile_role_name,
        },
        onSuccess: (res) => {
          Swal.fire({
            title: `${res.data.message}`,
          });
          setPageData("");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          handleBack();
        },
        onError: (error) => {
          Swal.fire({
            icon: "error",
            title: `${error.response.data.message}`,
          });
        },
      });
    }
  }

  const handleBack = () => {
    dispatch(
      changePage({
        page: "updateEmployee",
      })
    );

    navigate("/");
  };

  useEffect(() => {
    API({
      ...getProfile,
      params: { employee_code: username },
      onSuccess: (res) => {
        setEmployeeType(res.data.data.employee_type);
        setBrandId(res.data.data.brand_id);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }, []);

  const empType = [
    { value: "PLATFORM", label: "PLATFORM" },
    { value: "BRAND", label: "BRAND" },
  ];
  const filteredEmpType =
    employeeType === "BRAND"
      ? empType.filter((option) => option.value === "BRAND")
      : empType;
  return (
    <>
      <Header />

      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto px-5 pt-4 me-2 position-relative overflow-y-auto">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-3 mb-0"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
          }}
        >
          New Employee
        </h2>
        <div style={{ height: "80%" }}>
          <div className="row mt-2">
            <div className="col-6 position-relative">
              <InputForm
                type={"email"}
                id={"email_id"}
                name="email_id"
                placeholder={"Email Id"}
                valueState={pageData}
                onChange={(event) => {
                  pageDataFunc(event);
                  checkUniqueEmployee("registered_id", event.target.value);
                }}
                errorState={errors}
              />
              {uniqueEmployeeData && uniqueEmployeeData.registered_id && (
                <div
                  style={{ position: "absolute", right: "20px" }}
                  className={`message ${
                    uniqueEmployeeData.registered_id.unique ? "green" : "red"
                  }`}
                >
                  {uniqueEmployeeData.registered_id.message}
                </div>
              )}
            </div>
            <div className="col-6 position-relative">
              <InputForm
                type={"number"}
                id={"contact_no"}
                name="contact_no"
                placeholder={"Contact No"}
                valueState={pageData}
                onChange={(event) => {
                  pageDataFunc(event);
                  checkUniqueEmployee("registered_no", event.target.value);
                }}
                errorState={errors}
              />
              {uniqueEmployeeData && uniqueEmployeeData.registered_no && (
                <div
                  style={{ position: "absolute", right: "20px" }}
                  className={`message ${
                    uniqueEmployeeData.registered_no.unique ? "green" : "red"
                  }`}
                >
                  {uniqueEmployeeData.registered_no.message}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="employee_type"
                className="form-label"
              >
                Employee Type
              </label>
              <Select
                id={"employee_type"}
                name={"employee_type"}
                valueState={pageData["employee_type"]}
                onChange={(selectedOption, event) => {
                  const selectedEmployeeType = selectedOption.value;
                  setPageData({
                    ...pageData,
                    employee_type: selectedEmployeeType,
                  });
                  setSelectedEmployeeType(selectedOption.value);
                }}
                placeholder={"Employee Type"}
                options={filteredEmpType}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: errors["employee_type"]
                      ? "none"
                      : "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    border: errors["employee_type"]
                      ? "1px solid #DC4C64"
                      : "none",
                  }),
                }}
              />
              {errors["employee_type"] && (
                <div style={{ fontSize: "14px" }} className="text-danger mt-1">
                  {errors["employee_type"]}
                </div>
              )}
            </div>
            <div className="col-6">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="employee_type"
                className="form-label"
              >
                {selectedEmployeeType
                  ? selectedEmployeeType === "BRAND"
                    ? "Brand ID"
                    : "Platform ID"
                  : "Select Employee Type"}
              </label>
              <Select
                id={"reference_id"}
                name={"reference_id"}
                valueState={pageData["reference_id"]}
                onChange={(selectedOption, event) => {
                  const refId = selectedOption.value;
                  setPageData({
                    ...pageData,
                    reference_id: refId,
                  });
                }}
                placeholder={
                  selectedEmployeeType
                    ? selectedEmployeeType === "BRAND"
                      ? "Brand ID"
                      : "Platform ID"
                    : "Select Employee Type"
                }
                options={referenceOptions}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: errors["reference_id"]
                      ? "none"
                      : "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    border: errors["reference_id"]
                      ? "1px solid #DC4C64"
                      : "none",
                  }),
                }}
              />
              {errors["reference_id"] && (
                <div style={{ fontSize: "14px" }} className="text-danger mt-1">
                  {errors["reference_id"]}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <InputForm
                type={"text"}
                id={"employee_name"}
                name="employee_name"
                placeholder={"Employee Name"}
                valueState={pageData}
                onChange={(event) => pageDataFunc(event)}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"Employee Code"}
                id={"employee_code"}
                name="employee_code"
                valueState={pageData}
                onChange={(event) => {
                  pageDataFunc(event);
                  checkUniqueEmployee("username", event.target.value);
                }}
                errorState={errors}
              />
              {uniqueEmployeeData && uniqueEmployeeData.username && (
                <div
                  style={{ position: "absolute", right: "50px" }}
                  className={`message ${
                    uniqueEmployeeData.username.unique ? "green" : "red"
                  }`}
                >
                  {uniqueEmployeeData.username.message}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="profile_role"
                className="form-label"
              >
                Profile Role
              </label>
              <Select
                id={"profile_role"}
                name={"profile_role"}
                valueState={pageData["profile_role"]}
                onChange={(selectedOption) =>
                  setPageData({
                    ...pageData,
                    profile_id: selectedOption.label,
                    profile_role: selectedOption.value,
                  })
                }
                placeholder={"Profile Role"}
                options={profileOptions}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: errors["profile_role"]
                      ? "none"
                      : "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    border: errors["profile_role"]
                      ? "1px solid #DC4C64"
                      : "none",
                  }),
                }}
              />
              {errors["profile_role"] && (
                <div style={{ fontSize: "14px" }} className="text-danger mt-1">
                  {errors["profile_role"]}
                </div>
              )}
            </div>
            <div className="col-6">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="designation"
                className="form-label"
              >
                Designation
              </label>
              <Select
                type={"text"}
                id={"designation"}
                name="designation"
                valueState={pageData["designation"]}
                onChange={(selectedOption) =>
                  setPageData({
                    ...pageData,
                    designation: selectedOption.label,
                    designation_id: selectedOption.value,
                  })
                }
                placeholder={"Select Designation"}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: errors["designation"]
                      ? "none"
                      : "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    border: errors["designation"]
                      ? "1px solid #DC4C64"
                      : "none",
                  }),
                }}
                options={designationOptions}
              />
              {errors["designation"] && (
                <div style={{ fontSize: "14px" }} className="text-danger mt-1">
                  {errors["designation"]}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <Button onClick={handleBack} children={"Back"} />
          <Button onClick={onSubmit} type="submit" children={"Submit"} />
        </div>
      </div>
    </>
  );
};

export default NewEmployee;
