import axios from "axios";
import { store } from "../redux/store";
import { onLogout, updateT } from "../redux/slice/userSlice";

// Fetch public IP address
const getIPAddress = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    return response.data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
    return null;
  }
};

// Fetch geolocation
const getGeolocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error fetching geolocation:", error);
          reject(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      reject(null);
    }
  });
};

const apiConfig = {
  // baseURL: "https://aeskcloud.prayasfinance.in/",
  baseURL: "http://65.1.18.161/",
  // baseURL: "http://192.168.1.16:8000/",
  // baseURL: "http://192.168.1.45:8000/",
  timeout: 0,
};

const instance = axios.create(apiConfig);

const refreshAccessToken = async () => {
  try {
    const ipAddress = await getIPAddress();
    const geolocation = await getGeolocation();

    const response = await axios.post(`${apiConfig.baseURL}api/a/refresh/`, {
      refresh: store.getState().user.rt,
      ip_address: ipAddress,
      latitude: geolocation?.lat,
      longitude: geolocation?.long,
    });

    const t = response.data.data.access;
    store.dispatch(updateT({ t: t }));

    return t;
  } catch (error) {
    console.error("Error refreshing access token:", error);
    throw error;
  }
};

const handleRequest = async (options, retry = false) => {
  try {
    const response = await instance(options);
    return response;
  } catch (error) {
    if (error?.response?.status === 401 && !retry && store.getState().user.rt) {
      const newAccessToken = await refreshAccessToken();
      options.headers.Authorization = `Bearer ${newAccessToken}`;
      return handleRequest(options, true);
    } else {
      throw error;
    }
  }
};

const API = ({
  url,
  method,
  params = {},
  body = {},
  isFormData = false,
  uploadProgress = () => {},
  onError = (error, response) => {},
  onSuccess,
  retry = false,
}) => {
  const reduxState = store.getState();

  const data = () => {
    return method !== "GET" ? (isFormData ? body : JSON.stringify(body)) : null;
  };

  const progress = (progressPercent) => {
    if (isFormData) uploadProgress(progressPercent);
  };

  const headers = {
    Authorization: reduxState.user.t ? `Bearer ${reduxState.user.t}` : ``,
    "Content-Type": isFormData ? "multipart/form-data" : "application/json",
  };

  const options = {
    ...apiConfig,
    url: url,
    method: method,
    params: params,
    data: data(body),
    onUploadProgress: (progressEvent) =>
      progress(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
    headers: headers,
  };

  handleRequest(options, retry)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
      const isLoginEndpoint = window.location.href.includes("login");

      if (error?.response?.status === 401 && !isLoginEndpoint) {
        store.dispatch(onLogout());
        // window.location.href = "/login";
      } else {
        console.error("API request failed:", error);
      }
    });
};

export default API;
