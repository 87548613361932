import React, { useState } from "react";
import Select from "react-select";
import { apply, filter } from "../images";
import { ExportToExcel } from "./ExportToExcel";
import { GrPowerReset } from "react-icons/gr";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

// Date input component
const DateInputComponent = ({ onChange, id, value }) => {
  const [startDate, setStartDate] = useState(value ? new Date(value[0]) : null);
  const [endDate, setEndDate] = useState(value ? new Date(value[1]) : null);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      const formattedStart = format(start, "yyyy-MM-dd");
      const formattedEnd = format(end, "yyyy-MM-dd");
      onChange(id, [formattedStart, formattedEnd]);
    } else if (start) {
      const formattedStart = format(start, "yyyy-MM-dd");
      onChange(id, [formattedStart, formattedStart]);
    }
  };

  return (
    <DatePicker
      showIcon
      selected={startDate}
      onChange={handleDateChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      isClearable
      placeholderText="Select date or range"
      dateFormat="yyyy-MM-dd"
      className="form-control"
      style={{
        fontSize: "15px",
        width: "100%",
        padding: "8px 51px",
        borderRadius: "10px",
        backgroundColor: "red",
      }}
    />
  );
};

const InputComponent = ({ onChange, id, placeholder, type, value }) => {
  return (
    <input
      type={type}
      id={id}
      placeholder={placeholder}
      style={{
        fontSize: "15px",
        width: "100%",
        padding: "8px 5px",
        borderRadius: "10px",
        backgroundColor: "rgba(255, 231, 159, 0.4)",
      }}
      onChange={(e) => onChange(id, e.target.value)}
      value={value || ""}
    />
  );
};

const SelectComponent = ({ onChange, id, placeholder, options, value }) => {
  return (
    <Select
      options={options}
      placeholder={placeholder}
      value={
        value
          ? {
              value: value,
              label: value,
            }
          : null
      }
      onChange={(selectedOption) => onChange(id, selectedOption.value)}
      styles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: "rgba(255, 231, 159, 0.4)",
          borderRadius: "10px",
          width: "100%",
          border: "none",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "#000",
        }),
      }}
    />
  );
};

const Filter2 = ({
  filters = [],
  getDataFunc,
  excelFunc,
  excelFileName,
  children = <></>,
}) => {
  const [filterValues, setFilterValues] = useState({});

  const handleInputChange = (id, value) => {
    setFilterValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const resetFilters = () => {
    setFilterValues({});
    getDataFunc({ filterValues: {} });
  };

  return (
    <>
      <div
        className="mx-3 mt-4"
        style={{
          borderTop: "1.5px solid #D9D9D9",
          borderBottom: "2px solid #063E70",
        }}
      >
        <div
          className="px-3 mt-1 py-2 mb-4 d-flex align-items-center"
          style={{ backgroundColor: "#D9D9D9", borderTop: "1px solid #D9D9D9" }}
        >
          <h3
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
              color: "rgba(25, 66, 128, 1)",
            }}
            className="mb-0"
          >
            Filters
          </h3>
          <img className="ms-3" src={filter} alt="filter-icon" />
        </div>

        <div className="row pb-3 px-3">
          {filters.map((filter, key) => {
            return (
              <div className="col p-0 pe-2" key={key}>
                {filter.filterType === "select" ? (
                  <SelectComponent
                    value={
                      filterValues.hasOwnProperty(filter.id)
                        ? filterValues[filter.id]
                        : undefined
                    }
                    placeholder={filter.placeholder}
                    options={filter.options}
                    id={filter.id}
                    onChange={handleInputChange}
                  />
                ) : filter.filterType === "input" ? (
                  <InputComponent
                    id={filter.id}
                    placeholder={filter.placeholder}
                    type={filter.inputType}
                    onChange={handleInputChange}
                    value={
                      filterValues.hasOwnProperty(filter.id)
                        ? filterValues[filter.id]
                        : undefined
                    }
                  />
                ) : filter.filterType === "date" ? (
                  <DateInputComponent
                    id={filter.id}
                    onChange={handleInputChange}
                    value={
                      filterValues.hasOwnProperty(filter.id)
                        ? filterValues[filter.id]
                        : undefined
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
            );
          })}
          <div className="col-1 p-0">
            <img
              onClick={() => {
                getDataFunc({ filterValues: filterValues });
              }}
              style={{ cursor: "pointer", pointerEvents: "all" }}
              src={apply}
              alt="apply"
            />
          </div>
          <div className="col-1 p-0">
            <GrPowerReset
              style={{
                marginTop: "9px",
                marginLeft: "8px",
                fontSize: "18px",
                cursor: "pointer",
                color: "#194280",
              }}
              onClick={resetFilters}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-2 px-3">
        <ExportToExcel
          fetchOnClickFunc={() => {
            return excelFunc({ filterValues: filterValues });
          }}
          fileName={excelFileName}
        />
        {children}
      </div>
    </>
  );
};

export default Filter2;
