import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { circles } from "../../../images";
import Header from "../../../components/Header";
import { changePage } from "../../../redux/slice/dashboardSlice";
import { aepsOnBoardStatus } from "../../../urls";
import API from "../../../components/api2";
import loaderGif from "../../../gif/buffer.gif";

const ConfigureBanks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(true);

  const handle2fa = (bankKey) => {
    dispatch(changePage({ page: "twoFactorAuth" }));
    navigate("/", { state: { bankKey } });
  };
  const renderStatusIcon = (status) => {
    return status ? (
      <span style={{ color: "green" }}>✓</span>
    ) : (
      <span style={{ color: "red" }}>✗</span>
    );
  };

  useEffect(() => {
    API({
      ...aepsOnBoardStatus,
      onSuccess: (res) => {
        const fetchedBanks = Object.keys(res.data.data).map((key) => ({
          key,
          name: res.data.data[key].bank_name,
          onboardingStatus: res.data.data[key].onboarding_status,
          twoFA: res.data.data[key]["2fa"],
        }));
        setBanks(fetchedBanks);
        setLoading(false);
      },
      onError: (error) => {
        console.log("Error fetching banks: ", error);
        setLoading(false);
      },
    });
  }, []);

  return (
    <>
      <Header />
      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto px-4 py-4 me-2 position-relative overflow-y-auto">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="mb-0 pb-3"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
          }}
        >
          Configure Banks
        </h2>
        {loading ? (
          <div className="text-center mt-4">
            <img
              style={{ maxWidth: "60px" }}
              src={loaderGif}
              alt="loading..."
            />
          </div>
        ) : (
          <table className="configure-banks-table">
            <thead style={{ position: "sticky", top: "0" }}>
              <tr>
                <th>Bank Name</th>
                <th>Onboarding Status</th>
                <th>2FA</th>
              </tr>
            </thead>
            <tbody>
              {banks.map((bank, index) => (
                <tr key={index}>
                  <td>{bank.name}</td>
                  <td>
                    {renderStatusIcon(bank.onboardingStatus === "Accepted")}
                  </td>
                  <td>
                    {bank.onboardingStatus === "Accepted" && !bank.twoFA ? (
                      <button
                        onClick={() => handle2fa(bank.key)}
                        className="border-0 text-white px-2 py-1"
                        style={{
                          backgroundColor: "#194280",
                          borderRadius: "5px",
                        }}
                      >
                        Setup 2FA
                      </button>
                    ) : (
                      renderStatusIcon(bank.twoFA)
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <style jsx>{`
        .configure-banks-table {
          width: 100%;
          border-collapse: collapse;
          font-family: "Montserrat";
        }

        .configure-banks-table th,
        .configure-banks-table td {
          padding: 12px 15px;
          text-align: left;
          border-bottom: 1px solid #ddd;
        }

        .configure-banks-table th {
          background-color: #f4f4f4;
          font-weight: 600;
          font-size: 16px;
          color: #333;
        }

        .configure-banks-table tr:nth-child(even) {
          background-color: #f9f9f9;
        }

        .configure-banks-table tr:hover {
          background-color: #f1f1f1;
          cursor: pointer;
        }

        .configure-banks-table td {
          font-size: 14px;
          color: #555;
        }
      `}</style>
    </>
  );
};

export default ConfigureBanks;
