import React, { useEffect, useRef, useState } from "react";
import {
  bankTransferIcon,
  newLogo,
  serviceBackArrow,
  serviceCircle,
} from "../../../images";
import { changePage } from "../../../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import { registerRemitter } from "../../../urls";
import Swal from "sweetalert2";
import API from "../../../components/api2";
import Modal from "react-modal";
import loaderGif from "../../../gif/buffer.gif";

const RemitterRegistration = () => {
  const location = useLocation();
  const { resp, mobile_number } = location.state || {};
  const [loader, setLoader] = useState(false);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    dob: "",
    pincode: "",
    address: "",
    mobile_number: mobile_number || "",
    stateresp: resp || "",
    otp: "",
  });
  const [showOtpPopup, setShowOtpPopup] = useState(false);

  const inputRefs = useRef(Array(6).fill(null));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (mobile_number) {
      setFormData((prevState) => ({
        ...prevState,
        mobile_number,
      }));
    }
    if (resp) {
      setFormData((prevState) => ({
        ...prevState,
        stateresp: resp,
      }));
    }
  }, [mobile_number, resp]);

  // on change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile_number") {
      const formattedValue = value.replace(/\D/g, "");
      if (formattedValue.length <= 10) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      }
    } else if (name === "pincode") {
      const formattedValue = value.replace(/\D/g, "");
      if (formattedValue.length <= 6) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleOtpChange = (index, value, event) => {
    value = value.replace(/[^0-9]/g, "");

    const updatedOtp = [...formData.otp];
    updatedOtp[index] = value.charAt(0);

    setFormData((prevState) => ({
      ...prevState,
      otp: updatedOtp.join(""),
    }));

    if (inputRefs.current[index]) {
      inputRefs.current[index].value = value.charAt(0);
    }

    if (value !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    } else if (event.key === "Backspace" && index > 0 && value === "") {
      inputRefs.current[index - 1].focus();
    }
  };

  // validations
  const isFormValid = () => {
    return (
      formData.firstname &&
      formData.lastname &&
      formData.dob &&
      formData.pincode &&
      formData.address
    );
  };

  // hanlde register
  const handleRegister = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      setShowOtpPopup(true);
      resetOtp();
    }
  };

  // OTP
  const isOtpValid = () => {
    return formData.otp.length === 6;
  };

  const verifyOtp = () => {
    if (isOtpValid()) {
      setShowOtpPopup(false);
      setLoader(true);
      API({
        ...registerRemitter,
        body: formData,
        onSuccess: (res) => {
          setLoader(false);
          Swal.fire({
            title: `${res.data.data.message}`,
            customClass: {
              confirmButton: "swal-confirm-button",
              cancelButton: "swal-cancel-button",
            },
          }).then(() => {
            setLoader(true);
            setTimeout(() => {
              changePageFunc();
            }, 2000);
          });
        },
        onError: (error) => {
          setLoader(false);
          Swal.fire({
            icon: "error",
            title: `${error.response.data.message}`,
          });
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Please enter a valid OTP",
      });
    }
  };

  const resetOtp = () => {
    setFormData((prevState) => ({
      ...prevState,
      otp: "",
    }));
    inputRefs.current.forEach((ref) => {
      if (ref) {
        ref.value = "";
      }
    });
  };

  const closeModal = () => {
    setShowOtpPopup(false);
    resetOtp();
  };

  // change page
  const changePageFunc = () => {
    dispatch(changePage({ page: "queryRemitter" }));
    navigate("/");
  };
  const changePageFuncBack = () => {
    dispatch(changePage({ page: "home" }));
    navigate("/");
  };

  // date validation
  const todayDate = new Date();
  const eighteenYearsAgo = new Date(
    todayDate.getFullYear() - 18,
    todayDate.getMonth(),
    todayDate.getDate()
  )
    .toISOString()
    .split("T")[0];

  return (
    <>
      {/* Header  */}
      <div
        style={{ backgroundColor: "#D9D9D9" }}
        className="px-2 py-2 d-flex justify-content-between"
      >
        <img
          onClick={changePageFuncBack}
          style={{
            width: "220px",
            cursor: "pointer",
            marginLeft: "20px",
            padding: "6px 0",
            pointerEvents: "all",
          }}
          src={newLogo}
          alt="logo"
        />
        <Header
          style={{
            width: "75%",
            backgroundColor: "#194280",
            borderRadius: "10px",
            color: "#194280",
            position: "sticky",
            padding: "10px 0px",
            top: "0",
          }}
        />
      </div>
      <div className="mb-2 mt-1 mx-5 px-2 d-flex align-items-center">
        <div
          className="mb-0 px-2 d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "5px",
            color: "white",
            height: "50px",
            cursor: "pointer",
            pointerEvents: "all",
          }}
          onClick={changePageFunc}
        >
          <img src={serviceBackArrow} alt="back" />
        </div>
        <div className="d-flex ms-2 flex-wrap text-center justify-content-center">
          <div
            style={{ cursor: "pointer", margin: "0 20px" }}
            className="d-flex flex-column text-center position-relative "
          >
            <img
              style={{ width: "40px", margin: "6px auto 0 auto" }}
              src={bankTransferIcon}
              alt="icon"
            />
            <p
              style={{
                fontSize: "15px",
                fontWeight: "500",
              }}
              className="mb-0 login_text selected_service"
            >
              Bank Transfer
            </p>
          </div>
        </div>
      </div>

      {loader ? (
        <img
          className="position-absolute"
          style={{
            height: "70px",
            width: "70px",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          src={loaderGif}
          alt="Loading..."
        />
      ) : (
        <div
          style={{
            backgroundColor: "#ffff",
            borderRadius: "20px",
            border: "1px solid #D9D9D9",
            boxShadow: "0px 4px 40px 10px #00000026",
          }}
          className="mx-5 mt-4 p-2 position-relative"
        >
          <img
            style={{
              position: "absolute",
              right: "0",
              top: "0",
              width: "100px",
            }}
            src={serviceCircle}
            alt="circle"
          />
          <div className="row d-flex justify-content-between">
            <div className="col-6">
              <div
                className="px-4 position-relative"
                style={{
                  backgroundColor: "rgba(25, 66, 128, 1)",
                  borderRadius: "20px",
                }}
              >
                <p
                  style={{
                    fontSize: "20px",
                    color: "#ffff",
                  }}
                  className="mb-2 mx-5 pt-3"
                >
                  Remitter Registration
                </p>
                <div className="row justify-content-evenly">
                  <div className="col-5">
                    <label
                      htmlFor="firstname"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        opacity: formData.firstname ? 1 : 0,
                        fontSize: "15px",
                        marginTop: "8px",
                      }}
                      className="login_text mt-2"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      value={formData.firstname}
                      onChange={handleInputChange}
                      placeholder="First Name"
                      style={{
                        backgroundColor: "transparent",
                        borderBottom: formData.firstname
                          ? "1.5px solid white"
                          : "1.5px solid #ADACAC",
                        width: "100%",
                        color: "white",
                        borderRadius: "0px",
                        padding: "0px 8px",
                      }}
                      autoComplete="off"
                      className="login_text"
                    />

                    <label
                      htmlFor="mobile_number"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        opacity: formData.mobile_number ? 1 : 0,
                        fontSize: "15px",
                        marginTop: "8px",
                      }}
                      className="login_text mt-3"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      id="mobile_number"
                      name="mobile_number"
                      value={formData.mobile_number}
                      onChange={handleInputChange}
                      placeholder="Mobile Number"
                      style={{
                        backgroundColor: "transparent",
                        borderBottom: formData.mobile_number
                          ? "1.5px solid white"
                          : "1.5px solid #ADACAC",
                        width: "100%",
                        color: "white",
                        borderRadius: "0px",
                        padding: "0px 8px",
                      }}
                      autoComplete="off"
                      className="login_text"
                      readOnly
                    />

                    <label
                      htmlFor="pincode"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        opacity: formData.pincode ? 1 : 0,
                        fontSize: "15px",
                        marginTop: "8px",
                      }}
                      className="login_text mt-3"
                    >
                      Pincode
                    </label>
                    <input
                      type="number"
                      id="pincode"
                      name="pincode"
                      value={formData.pincode}
                      onChange={handleInputChange}
                      placeholder="Pincode"
                      style={{
                        backgroundColor: "transparent",
                        borderBottom: formData.pincode
                          ? "1.5px solid white"
                          : "1.5px solid #ADACAC",
                        width: "100%",
                        color: "white",
                        borderRadius: "0px",
                        padding: "0px 8px",
                      }}
                      autoComplete="off"
                      className="login_text"
                    />
                  </div>
                  <div className="col-5">
                    <label
                      htmlFor="lastname"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        opacity: formData.lastname ? 1 : 0,
                        fontSize: "15px",
                        marginTop: "8px",
                      }}
                      className="login_text mt-2"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      value={formData.lastname}
                      onChange={handleInputChange}
                      placeholder="Last Name"
                      style={{
                        backgroundColor: "transparent",
                        borderBottom: formData.lastname
                          ? "1.5px solid white"
                          : "1.5px solid #ADACAC",
                        width: "100%",
                        color: "white",
                        borderRadius: "0px",
                        padding: "0px 8px",
                      }}
                      autoComplete="off"
                      className="login_text"
                    />

                    <label
                      htmlFor="dob"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        fontSize: "15px",
                        marginTop: "8px",
                      }}
                      className="login_text mt-3"
                    >
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      id="dob"
                      name="dob"
                      max={eighteenYearsAgo}
                      value={formData.dob}
                      onChange={handleInputChange}
                      placeholder="Date of Birth"
                      style={{
                        backgroundColor: "transparent",
                        borderBottom: formData.dob
                          ? "1.5px solid white"
                          : "1.5px solid #ADACAC",
                        width: "100%",
                        color: "white",
                        borderRadius: "0px",
                        padding: "0px 8px",
                      }}
                      autoComplete="off"
                      className="login_text custom-date-input"
                    />

                    <label
                      htmlFor="address"
                      style={{
                        color: "#ADACAC",
                        transition: "all 0.4s ease-in-out",
                        opacity: formData.address ? 1 : 0,
                        fontSize: "15px",
                        marginTop: "8px",
                      }}
                      className="login_text mt-3"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      placeholder="Address"
                      style={{
                        backgroundColor: "transparent",
                        borderBottom: formData.address
                          ? "1.5px solid white"
                          : "1.5px solid #ADACAC",
                        width: "100%",
                        color: "white",
                        borderRadius: "0px",
                        padding: "0px 8px",
                      }}
                      autoComplete="off"
                      className="login_text"
                    />
                  </div>
                </div>
                <div className="text-center pb-3 mt-5">
                  <button
                    className="px-4 py-2 login_text"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      border: "none",
                      fontSize: "15px",
                      fontWeight: "500",
                      opacity: isFormValid() ? 1 : 0.5,
                      cursor: isFormValid() ? "pointer" : "not-allowed",
                    }}
                    disabled={!isFormValid()}
                    onClick={handleRegister}
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* OTP Popup */}
      <Modal
        isOpen={showOtpPopup}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        contentLabel="OTP Verification"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            padding: "20px",
            borderRadius: "10px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        <div className="text-center py-4">
          <p
            className="login_text"
            style={{
              fontWeight: "600",
              fontSize: "20px",
              color: "rgba(79, 87, 91, 1)",
              letterSpacing: "1px",
            }}
          >
            Please enter the One-Time Password to verify account
          </p>
          <p
            className="mt-2 login_text"
            style={{
              fontWeight: "400",
              fontSize: "15px",
              color: "rgba(79, 87, 91, 1)",
              letterSpacing: "1px",
            }}
          >
            OTP sent to{" "}
            <span style={{ fontWeight: "600" }}>{mobile_number}</span>
          </p>
          <div className="mt-5 d-flex justify-content-center">
            {Array.from({ length: 6 }).map((_, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                className="mx-1"
                ref={(el) => (inputRefs.current[index] = el)}
                onChange={(e) => handleOtpChange(index, e.target.value, e)}
                onKeyDown={(e) => handleOtpChange(index, e.target.value, e)}
                value={formData.otp[index] || ""}
                style={{
                  width: "40px",
                  height: "40px",
                  textAlign: "center",
                  fontSize: "20px",
                  borderBottom: "1px solid black",
                  borderRadius: "0px",
                }}
              />
            ))}
          </div>
          <div className="text-center mt-5">
            <button
              type="button"
              className="border-0 text-white py-1 px-4 mt-4 col-5 login_text"
              style={{
                backgroundColor: "#194280",
                borderRadius: "10px",
                fontSize: "18px",
                opacity: isOtpValid() ? 1 : 0.5,
                cursor: isOtpValid() ? "pointer" : "not-allowed",
              }}
              onClick={verifyOtp}
              disabled={!isOtpValid()}
            >
              Verify
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RemitterRegistration;
