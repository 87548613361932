import React, { useEffect } from "react";
import { currentTripDetails } from "../../../../urls";
import API from "../../../../components/api2";

const SelectSeats = ({ tripId }) => {
  // Fetch current trip detail
  useEffect(() => {
    const fetchCurrentTrip = () => {
      API({
        ...currentTripDetails,
        body: { trip_id: tripId },
        onSuccess: (res) => {
          console.log(res.data.data.seats);
        },
        onError: (error) => {
          console.error("Error fetching current trip:", error);
        },
      });
    };
    fetchCurrentTrip();
  }, [tripId]);

  return (
    <div className="container mt-4">
      <div
        style={{
          backgroundColor: "#D9D9D970",
          backdropFilter: "blur(0px)",
          minHeight: "50vh",
          borderRadius: "5px",
        }}
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, nemo
        magnam asperiores facilis maxime, voluptatem officia cum perferendis
        amet pariatur ad laboriosam maiores commodi! Laborum, doloribus id eaque
        est, amet molestias excepturi, sequi ipsa nostrum ipsum labore
        recusandae inventore provident animi incidunt temporibus quis corrupti
        voluptatem. Esse laudantium repellat laboriosam!
      </div>
    </div>
  );
};

export default SelectSeats;
