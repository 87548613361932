import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { download, excelDownload } from "../images";

export const ExportToExcel = ({
  apiData,
  fileName,
  style,
  fetchOnClickFunc = false,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    if (!apiData || !apiData.length) {
      console.error("Invalid apiData for export");
      return;
    }

    if (apiData) {
      // Decode the Base64 string
      const decodedExcelData = atob(apiData);

      // Convert the decoded data to a Uint8Array
      const arrayBuffer = new Uint8Array(decodedExcelData.length);
      for (let i = 0; i < decodedExcelData.length; i++) {
        arrayBuffer[i] = decodedExcelData.charCodeAt(i);
      }

      // Create a Blob from the ArrayBuffer
      const blob = new Blob([arrayBuffer], { type: fileType });

      // Save the Blob as a file using FileSaver.js
      FileSaver.saveAs(blob, fileName + fileExtension);
    } else {
      console.log("error");
    }
  };

  return (
    <button
      className="p-1"
      style={{ border: "1px solid black", borderRadius: "10px" }}
      onClick={(e) => {
        if (fetchOnClickFunc) {
          fetchOnClickFunc().then((excelData) => {
            exportToCSV(excelData, fileName);
          });
        } else {
          exportToCSV(apiData, fileName);
        }
      }}
    >
      <img
        style={{ height: "22px" }}
        src={excelDownload}
        alt="Excel Download"
      />
      <img
        className="ms-2"
        style={{ height: "22px" }}
        src={download}
        alt="Excel Download"
      />
    </button>
  );
};
