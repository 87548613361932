import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "./Button";
import { payFee } from "../urls";
import API from "../components/api2";
import Swal from "sweetalert2";

const Payment = ({ applicationType, appId }) => {
  const [show, setShow] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [proceedToPayDisabled, setProceedToPayDisabled] = useState(true);
  const [amount, setAmount] = useState("0");
  const [showTotal, setShowTotal] = useState(false);
  const [showPaymentMode, setShowPaymentMode] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    handleShow();
  }, []);

  const submitFee = () => {
    if (paymentMode && amount) {
      const requestBody = {
        payment_mode: paymentMode,
        transaction_amount: amount,
        application_type: "MERCHANT",
        application_no: appId,
      };

      setTimeout(() => {
        API({
          ...payFee,
          body: requestBody,
          onSuccess: (res) => {
            Swal.fire({
              title: `${res.data.message}`,
            }).then(() => {
              window.location.reload();
            });
            setShow(false);
          },
          onError: (error) => {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.message}`,
            });
          },
        });
      }, 3000);
    } else {
      console.error("Payment mode or transaction amount is missing.");
    }
  };
  const handlePaymentModeChange = (e) => {
    setPaymentMode(e.target.value);
    setErrorMessage("");
  };

  const handleDone = () => {
    if (paymentMode === "") {
      setErrorMessage("Please select any payment mode!");
      setProceedToPayDisabled(true);
    } else {
      setErrorMessage("");
      setProceedToPayDisabled(false);
      setShowTotal(true);
      setShowPaymentMode(true);
    }
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="row">
            <div
              className="col-7 px-4"
              style={{ borderRight: "1px solid rgba(105, 102, 102, 1)" }}
            >
              <h2
                className="mt-3"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "18px",
                  color: "rgba(26, 25, 24, 1)",
                }}
              >
                Pay Application Fee
              </h2>
              <div className="px-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                  className="mb-0 mt-5"
                >
                  {applicationType === "MERCHANT" ? "Merchant ID" : "Brand ID"}{" "}
                  : {appId}
                </p>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                  className="mb-0 mt-3"
                >
                  Application fees - {amount}
                </p>
                <div className="d-flex align-items-center mt-3 position-relative">
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                    className="mb-0"
                  >
                    Payment mode -
                  </p>
                  <Form.Group>
                    <div className="d-flex align-items-center justify-content-between ps-4">
                      <Form.Check
                        className="mb-0"
                        type="radio"
                        label="Cash"
                        name="paymentMode"
                        value="Cash"
                        checked={paymentMode === "Cash"}
                        onChange={handlePaymentModeChange}
                        id="cash-radio"
                      />
                      <Form.Check
                        className="ms-3 mb-0"
                        type="radio"
                        label="Online"
                        name="paymentMode"
                        value="Online"
                        checked={paymentMode === "Online"}
                        onChange={handlePaymentModeChange}
                        id="online-radio"
                      />
                    </div>
                  </Form.Group>
                  {errorMessage && (
                    <p
                      className="mb-0 position-absolute mt-5"
                      style={{ color: "red", left: "150px" }}
                    >
                      {errorMessage}
                    </p>
                  )}
                </div>
              </div>
              <div
                style={{ marginTop: "200px" }}
                className="d-flex justify-content-between"
              >
                <Button children={"Back"} onClick={handleClose} />
                <Button children={"Done"} onClick={handleDone} />
              </div>
            </div>
            <div className="col-5">
              <div
                className="px-3 py-4"
                style={{
                  backgroundColor: "rgba(22, 64, 127, 1)",
                  borderRadius: "5px",
                }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "white",
                    }}
                    className="mb-0"
                  >
                    Payment Mode
                  </p>
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "white",
                    }}
                    className="mb-0"
                  >
                    {showPaymentMode ? paymentMode : "None"}
                  </p>
                </div>
                <div
                  style={{ borderBottom: "1.5px solid white" }}
                  className="d-flex align-items-center justify-content-between mt-4 pb-3"
                >
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "white",
                    }}
                    className="mb-0"
                  >
                    Total
                  </p>
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "white",
                    }}
                    className="mb-0"
                  >
                    {showTotal ? `₹ ${amount}` : "0"}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-4">
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "white",
                    }}
                    className="mb-0"
                  >
                    Grand Total
                  </p>
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "white",
                    }}
                    className="mb-0"
                  >
                    {showTotal ? `₹ ${amount}` : "0"}
                  </p>
                </div>
                <div className="mt-5">
                  <button
                    className="border-0 text-white px-3 py-2 w-100"
                    style={{
                      backgroundColor: proceedToPayDisabled
                        ? "#989898"
                        : "rgba(255, 162, 1, 1)",
                      borderRadius: "5px",
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                      cursor: proceedToPayDisabled ? "not-allowed" : "pointer",
                    }}
                    onClick={submitFee}
                  >
                    Proceed to Pay
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Payment;
