import LoginPage from "./LoginPage";
import ChangePassword from "./ChangePassword";
import NewEmployee from "./NewEmployee";
import ResetPassword from "./ResetPassword";
import AssignRights from "./AssignRights";
import NewBusinessBrand from "./NewBusinessBrand";
import UpdateBrand from "./UpdateBrand";
import NewMerchant from "./NewMerchant";
import UpdateEmployee from "./UpdateEmployee";
import NewStandardService from "./NewStandardService";
import StandardServiceInfo from "./StandardServiceInfo";
import NewServiceProvider from "./NewServiceProvider";
import Home from "./Home";
import OtpVerification from "./OtpVerification";
import FirstLogin from "./FirstLogin";
import FirstLoginQuestions from "../components/FirstLoginQuestions";
import NewMerchantWallet from "./NewMerchantWallet";
import ValidateUser from "./ValidateUser";
import { ValidateQues } from "./ValidateQues";
import ForgetPassowrd from "./ForgetPassword";
import SetUpin from "./SetUpin";
import VerifyEmail from "./VerifyEmail";
import BrandInformation from "./BrandInformation";
import EmployeeRights from "./EmployeeRights";
import QRPage from "./QRPage";
import AdminResetPage from "./AdminResetPage";
import EmployeeInformation from "./EmployeeInformation";
import NewMenu from "./NewMenu";
import MenuList from "./MenuList";
import ManageRoles from "./ManageRoles";
import RoleInformation from "./RoleInformation";
import AllotPackage from "./AllotPackage";
import NewMerchantForm from "./NewMerchantForm";
import AllStandardServices from "./AllStandardServices";
import AllServiceProvider from "./AllServiceProvider";
import ServiceProviderInfo from "./ServiceProviderInfo";
import NewCommission from "./NewCommission";
import AllCommisions from "./AllCommisions";
import CommissionInfo from "./CommissionInfo";
import AllApiPortal from "./AllApiPortal";
import NewApiPortal from "./NewApiPortal";
import ApiPortalInfo from "./ApiPortalInfo";
import AllConsumer from "./AllConsumer";
import NewConsumer from "./NewConsumer";
import ConsumerInfo from "./ConsumerInfo";
import AllMerchantApplication from "./AllMerchantApplication";
import MerchantAppInfo from "./MerchantAppInfo";
import AllMerchants from "./AllMerchants";
import MerchantInfo from "./MerchantInfo";
import Services from "./Services";
import AllEmployeeRights from "./AllEmployeeRights";
import NewBusinessPlan from "./NewBusinessPlan";
import AllBusinessScheme from "./AllBusinessScheme";
import BusinessSchemeInfo from "./BusinessSchemeInfo";
import AllotBusinessScheme from "./AllotBusinessScheme";
import HelpDeskModal from "../components/HelpDeskModal";
import VerifyForgotPassMail from "./VerifyForgotPassMail";
import OtpVerifyForget from "./OtpVerifyForget";
import { ValidateQuesForget } from "./ValidateQuesForget";
import ImageViewer from "../components/ImageViewer";
import MerchantAllotPackage from "./MerchantAllotPackage";
import RechargeMerchant from "./RechargeMerchant";
import NewEndCustomer from "./NewEndCustomer";
import AddWorkflow from "./AddWorkflow";
import AllWorkflows from "./AllWorkflows";
import WorkflowInfo from "./WorkflowInfo";
import AllTickets from "./AllTickets";
import TicketInfo from "./TicketInfo";
import AllEndCustomers from "./AllEndCustomers";
import EndCustomerInfo from "./EndCustomerInfo";
import ChangePassProfile from "./ChangePassProfile";
import ProfileSettings from "./ProfileSettings";
import MobileRecharge from "./services/Recharge/MobileRecharge";
import DthRecharge from "./services/Recharge/DthRecharge";
import ServiceTabs from "../components/ServiceTabs";
import AddRole from "./AddRole";
import Payment from "./PaymentPage";
import AepsWithdrawal from "./services/Aeps/Withdrawal/AepsWithdrawal";
import AepsBalance from "./services/Aeps/BalanceEnquiry/AepsBalance";
import AepsAadharPay from "./services/Aeps/AadharPay/AepsAadharPay";
import AepsStatement from "./services/Aeps/MiniStatement/AepsStatement";
import OnBoarding from "./services/Aeps/OnBoarding";
import TwoFactorAuth from "./services/Aeps/TwoFactorAuth";
import OtpVerifyMail from "../pages/OtpVerifyMail";
import CustomerList from "./services/Recharge/CustomerList";
import PlatformTransactions from "./PlatformTransactions";
import BrandTransactions from "./BrandTransactions";
import MerchantTransactions from "./MerchantTransactions";
import QueryRemitter from "./services/DMT/QueryRemitter";
import RemitterRegistration from "./services/DMT/RemitterRegistration";
import AddBeneficiary from "./services/DMT/AddBeneficiary";
import PostPaidMobile from "./services/BBPS/PostPaidMobile";
import ChangeUpin from "./ManageUpin/ChangeUpin";
import ResetUpin from "./ManageUpin/ResetUpin";
import ConfigureBanks from "./services/Aeps/ConfigureBanks";
import CheckCustomer from "../components/CheckCustomer";
import BusBookHome from "./services/Travel/BusBooking/BusBookHome";

// import ViewerPage from "./ViewerPage";

let pages = {
  // Home
  home: Home,
  services: Services,

  // profile
  profileSettings: ProfileSettings,

  // image viewer
  imageView: ImageViewer,

  // brand
  addBusinessBrand: NewBusinessBrand,
  updateBusinessBrand: UpdateBrand,
  brandInformation: BrandInformation,

  // merchant
  newMerchant: NewMerchant,
  newMerchantForm: NewMerchantForm,
  allMerchantApplication: AllMerchantApplication,
  allMerchants: AllMerchants,
  merchantAppInfo: MerchantAppInfo,
  allMerchantInfo: MerchantInfo,
  allotMerchPackage: MerchantAllotPackage,
  rechargeMerchant: RechargeMerchant,

  // service provider
  addServiceProvider: NewServiceProvider,
  allServiceProviders: AllServiceProvider,
  serviceProviderInfo: ServiceProviderInfo,

  // Employee
  newEmployee: NewEmployee,
  updateEmployee: UpdateEmployee,
  allEmployeeRights: AllEmployeeRights,
  employeeRights: EmployeeRights,
  employeeInformation: EmployeeInformation,

  // standard service
  allStandardServices: AllStandardServices,
  addStandardService: NewStandardService,
  standardServiceInfo: StandardServiceInfo,

  // Merchant Wallet
  newMerchWallet: NewMerchantWallet,

  // Reset
  resetPassword: ResetPassword,
  changePassword: ChangePassword,
  changePassProfile: ChangePassProfile,
  adminReset: AdminResetPage,

  // Upin
  setUpin: SetUpin,
  manageUpin: ChangeUpin,
  resetUpin: ResetUpin,

  // OTP
  verifyOTP: OtpVerification,
  verifyMailOtp: OtpVerifyMail,

  // First Login
  firstLogin: FirstLogin,

  // Login
  login: LoginPage,

  // verify mail
  verifyMail: VerifyEmail,

  // questions
  getQuestions: FirstLoginQuestions,

  // forget password
  validateUser: ValidateUser,
  verifyForgotMail: VerifyForgotPassMail,
  otpVerifyForget: OtpVerifyForget,
  validateQues: ValidateQues,
  validateQuesForget: ValidateQuesForget,
  forgetPassword: ForgetPassowrd,

  // Assign rights
  assignRights: AssignRights,

  // Menu
  newMenu: NewMenu,
  menuList: MenuList,

  // Roles
  addRole: AddRole,
  manageRoles: ManageRoles,
  roleInformation: RoleInformation,

  // qr
  qR: QRPage,

  // Allot Package
  allotPackage: AllotPackage,

  // Commision
  addCommission: NewCommission,
  getCommissions: AllCommisions,
  commissionInfo: CommissionInfo,

  // Api & Portal
  addApiPortal: NewApiPortal,
  getApiPortal: AllApiPortal,
  apiPortalInfo: ApiPortalInfo,

  // consumer
  addConsumerService: NewConsumer,
  getConsumer: AllConsumer,
  consumerInfo: ConsumerInfo,

  // business plan
  newBusinessPlan: NewBusinessPlan,
  allBusinessScheme: AllBusinessScheme,
  businessSchemeInfo: BusinessSchemeInfo,
  allotBusinessScheme: AllotBusinessScheme,

  // help desk
  helpDesk: HelpDeskModal,

  // end customer
  newEndCustomer: NewEndCustomer,
  allEndCustomers: AllEndCustomers,
  endCustomerInfo: EndCustomerInfo,

  // workFlow
  addWorkflow: AddWorkflow,
  allWorkflows: AllWorkflows,
  workflowInfo: WorkflowInfo,

  // tickets
  allTickets: AllTickets,
  ticketInfo: TicketInfo,

  // payment
  payment: Payment,

  // platform Transactions
  platformTransactions: PlatformTransactions,
  // Brand Transactions
  brandTransactions: BrandTransactions,
  // Merchant Transactions
  merchantTransactions: MerchantTransactions,

  //***************/ services *************

  serviceTabs: ServiceTabs,

  // recharge services

  // 1.recharge
  mobileRecharge: MobileRecharge,
  customerList: CustomerList,
  dthRecharge: DthRecharge,

  // 2.aeps

  // on boarding
  onBoarding: OnBoarding,
  configureBanks: ConfigureBanks,

  // two factor auth
  twoFactorAuth: TwoFactorAuth,

  aepsWithdrawal: AepsWithdrawal,
  aepsBalance: AepsBalance,
  aepsAadharPay: AepsAadharPay,
  aepsStatement: AepsStatement,

  // 3. DMT
  checkCustomer: CheckCustomer,
  queryRemitter: QueryRemitter,
  remitterReg: RemitterRegistration,
  addBeneficiary: AddBeneficiary,

  // 4. BBPS
  bbpsMobPostpaidRecharge: PostPaidMobile,

  // 5. Travel
  busBooking: BusBookHome,
};

export default pages;
